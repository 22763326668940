import React, { useEffect, useState } from "react";
import "../CSS/Certificates.scss";
import steffidhingra from "../Media/certificates/steffidhingra.webp";
import surbhitsinghal from "../Media/certificates/surbhitsinghal.webp";
import tanujadas from "../Media/certificates/tanujadas.webp";
import vikashkumar from "../Media/certificates/vikashkumar.webp";
import vinayak from "../Media/certificates/vinayak.webp";

export default function Certificates() {
  const [scroll, setScroll] = useState(false);
  const heightFromTop = 25;

  useEffect(() => {
    const scrollFunc = () => {
      const scrolled = window.scrollY >= heightFromTop;
      setScroll(scrolled);
    };

    window.addEventListener("scroll", scrollFunc);

    return () => {
      window.removeEventListener("scroll", scrollFunc);
    };
  }, [heightFromTop]);
  return (
    <>
      <div className="container-fluid Certificates_Container">
        <div
          className={scroll ? "row rowScrolledDiv" : "row rowNormalDiv"}
          style={scroll ? { background: "none" } : { background: `none` }}
        >
          <div className="col-12">
            <h1>Certificates</h1>
          </div>
        </div>
      </div>
      <div className={scroll ? "rowScrolledform" : "rowNormalform"}>
        <div className="container mt-5">
          <div className="row d-flex">
            <div className="col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3">
              <img src={surbhitsinghal} alt="surbhit singhal" />
            </div>
            <div className="col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3">
              <img src={steffidhingra} alt="steffi dhingra" />
            </div>
            <div className="col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3">
              <img src={tanujadas} alt="tanuja das" />
            </div>
            <div className="col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3">
              <img src={vikashkumar} alt="vikash kumar" />
            </div>
            <div className="col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3">
              <img src={vinayak} alt="vinayak" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
