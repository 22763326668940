
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCFXGwpe0kVK0ZiOXOKOBADpd005kQh1sU",
  authDomain: "pst-analytics-1eb69.firebaseapp.com",
  projectId: "pst-analytics-1eb69",
  storageBucket: "pst-analytics-1eb69.appspot.com",
  messagingSenderId: "433053456174",
  appId: "1:433053456174:web:3923231c8c5ce3f8788cbf",
  measurementId: "G-S91XGCE1ST"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db};



