import React from 'react';
import '../CSS/Achievement.scss';


export default function Achievement({Our_Acheivements}) {
    return (
        <>
            <div className='card achievement col col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                <div className='A_name'>
                    {Our_Acheivements.title}
                </div>
                <div className='A_digit'>
                    {Our_Acheivements.number}
                </div>
            </div>
        </>
    )
}
