import React, { useEffect, useState } from "react";
import "../CSS/Testimonials.scss";


export default function Testimonials() {
  const [scroll, setScroll] = useState(false);
  const heightFromTop = 25;

  useEffect(() => {
    const scrollFunc = () => {
      const scrolled = window.scrollY >= heightFromTop;
      setScroll(scrolled);
    }

    window.addEventListener('scroll', scrollFunc);

    return () => {
      window.removeEventListener('scroll', scrollFunc);
    }
  }, [heightFromTop])
  return (
    <>
      <div className="container-fluid Testimonials_Container">
        <div className={scroll ? 'row rowScrolledDiv' : 'row rowNormalDiv'} style={scroll ? { background: 'none' } : { background: `none` }}>
          <div className='col-12' >
            <h1>
              Testimonials
            </h1>
          </div>
        </div>
      </div>
      <div className={scroll ? 'rowScrolledform' : 'rowNormalform'}>
      Testimonials
      </div>
    </>
  )
}