import React from "react";
import "./SCSS/Overview.scss";

export default function Overview({ What_YouLL_Learn, Quick_Stats }) {
  return (
    <>
      <div className="overview">
        {What_YouLL_Learn.hasOwnProperty("p1") && <p>{What_YouLL_Learn.p1}</p>}
        {What_YouLL_Learn.hasOwnProperty("p2") && <p>{What_YouLL_Learn.p2}</p>}
        {What_YouLL_Learn.hasOwnProperty("p3") && <p>{What_YouLL_Learn.p3}</p>}
        {What_YouLL_Learn.hasOwnProperty("p4") && <p>{What_YouLL_Learn.p4}</p>}
        {What_YouLL_Learn.hasOwnProperty("p5") && <p>{What_YouLL_Learn.p5}</p>}
        <h5>Quick Stats</h5>
        {Quick_Stats.hasOwnProperty("first") && (
          <div>
            <div>
              <i className="bi bi-award"></i>
            </div>
            <p>{Quick_Stats.first}</p>
          </div>
        )}
        {Quick_Stats.hasOwnProperty("second") && (
          <div>
            <div>
              <i className="bi bi-bookmark"></i>
            </div>
            <p>{Quick_Stats.second}</p>
          </div>
        )}
        {Quick_Stats.hasOwnProperty("third") && (
          <div>
            <div>
              <i className="bi bi-hand-thumbs-up"></i>
            </div>
            <p>{Quick_Stats.third}</p>
          </div>
        )}
        {Quick_Stats.hasOwnProperty("fourth") && (
          <div>
            <div>
              <i className="bi bi-award"></i>
            </div>
            <p>{Quick_Stats.fourth}</p>
          </div>
        )}
        {Quick_Stats.hasOwnProperty("fifth") && (
          <div>
            <div>
              <i className="bi bi-bookmark"></i>
            </div>
            <p>{Quick_Stats.fifth}</p>
          </div>
        )}

      </div>
    </>
  );
}
