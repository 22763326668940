import React, { useEffect, useState } from "react";
import "./Module CSS/Module.scss";
import { CourseData } from "../../Constants/CourseData";
import Interested from "./Interested";
import Dataanalytics from "./ModulesImages/dataanalytics.jpg";
import Curriculum from "./ModulesInnerComponents/Curriculum";
import Instructor from "./ModulesInnerComponents/Instructor";
import FAQs from "./ModulesInnerComponents/FAQs";
import Outcome from "./ModulesInnerComponents/Outcome";
import Benefits from "./ModulesInnerComponents/Benefits";
import Overview from "./ModulesInnerComponents/Overview";
import WhoShouldAttend from "./ModulesInnerComponents/WhoShouldAttend";
import { Helmet } from "react-helmet";

export default function DataAnalytics() {
  const [scroll, setScroll] = useState(false);
  const heightFromTop = 25;

  useEffect(() => {
    const scrollFunc = () => {
      const scrolled = window.scrollY >= heightFromTop;
      setScroll(scrolled);
    };

    window.addEventListener("scroll", scrollFunc);

    return () => {
      window.removeEventListener("scroll", scrollFunc);
    };
  }, [heightFromTop]);

  return (
    <>
      <Helmet>
        <title>
          Data Analytics Training In Delhi | Sas Course In Delhi | Data
          Analytics Using Sas Training In Gurgaon | SAS Training{" "}
        </title>
        <meta
          name="description"
          content="PST Analytics provides the best Data Analytics coaching in Noida, SAS Training In Delhi, Data Analytics Using Sas Course In Delhi, Sas Training In Gurgaon, and Delhi NCR. Learn concepts of Analytics Predictive modelling with the hands-on application through SAS SQL R Python Excel for cracking global certification with PST Analytics, classroom training."
        />
        <meta
          name="keywords"
          content="Data Analytics Training In Delhi, Data Analytics Using SAS Course In Delhi, Data Analytics Using SAS Training In Gurgaon, Best Data Analytics Institute In Delhi"
        />
      </Helmet>
      <div className="container-fluid course-card">
        <div className="row">
          <div
            className={
              scroll
                ? "img_containerScrolled"
                : "img_containerNormal col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9"
            }
            style={{ background: `url(${Dataanalytics})` }}
          >
            <div className="bg-div">
              <h1 className="courseHeading">
                {CourseData.DataAnalytics_usingSAS.Title}
              </h1>
              <div className="desc_">
                <p>{CourseData.DataAnalytics_usingSAS.Subtitle}</p>
                <h5>{CourseData.DataAnalytics_usingSAS.Time} hours</h5>
                <h5>{CourseData.DataAnalytics_usingSAS.Students}</h5>
                <h5>Basic</h5>
                <h5>SAS Certified</h5>
              </div>
            </div>
          </div>
          <div
            className={
              scroll
                ? "scrolledInterestedDiv col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"
                : "col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 normalInterestedDiv"
            }
          >
            <Interested />
          </div>
        </div>

        {/* Navbar */}

        <ul
          className={
            scroll
              ? "nav justify-content-center  nav-tabs container description_Nav_Scrolled"
              : "nav justify-content-center nav-tabs container description_Nav_Normal"
          }
          id="list-example"
        >
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#list-item-1">
              Overview
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-2">
              Benefits
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-3">
              Outcome
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-4">
              Curriculum
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-5">
              Instructor
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-6">
              FAQs
            </a>
          </li>
        </ul>

        {/* overview */}

        <div className="container overview_Cont">
          <div
            data-bs-spy="scroll"
            data-bs-target="#list-example"
            data-bs-offset="0"
            className="scrollspy-example"
            tabIndex="0"
          >
            <div
              id="list-item-1"
              className={scroll ? "list-item-1scrolled" : "list-item-1normal"}
            >
              <h4 style={scroll ? {} : { marginTop: "2rem" }}>
                What you'll learn
              </h4>
              <Overview
                What_YouLL_Learn={
                  CourseData.DataAnalytics_usingSAS.What_YouLL_Learn
                }
                Quick_Stats={CourseData.DataAnalytics_usingSAS.Quick_Stats}
              />
            </div>
            <div id="list-item-2">
              <h4>Benefits</h4>
              <Benefits Benefits={CourseData.DataAnalytics_usingSAS.Benefits} />
              <h4>Who Should Attend</h4>
              <WhoShouldAttend
                WhoShouldAttend={
                  CourseData.DataAnalytics_usingSAS.Who_Should_Attend
                }
              />
            </div>
            <div id="list-item-3">
              <h4>Course Outcome</h4>
              <Outcome
                Outcome={CourseData.DataAnalytics_usingSAS.Course_Outcome}
              />
            </div>
            <div id="list-item-4">
              <h4>Curriculum</h4>
              <Curriculum
                Curriculum={CourseData.DataAnalytics_usingSAS.Curriculum}
              />
            </div>
            <div id="list-item-5">
              <h4>Instructor</h4>
              <Instructor
                Instructors={CourseData.DataAnalytics_usingSAS.Instructors}
              />
            </div>
            <div id="list-item-6">
              <h4>FAQs</h4>
              <FAQs FAQs={CourseData.DataAnalytics_usingSAS.FAQs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
