import React from 'react';
import './SCSS/WhoShouldAttend.scss';

export default function WhoShouldAttend({WhoShouldAttend}) {
    return (
        <>
            <div className='WhoShouldAttend'>
                {
                    WhoShouldAttend.length>0 && WhoShouldAttend.map((item, index) => (
                        <div key={index}>
                        <i className="bi bi-caret-right"></i>
                        <p>{item}</p>
                    </div>
                    ))
                }
            </div>
        </>
    )
}
