import React, { useEffect, useState } from "react";
import "./Module CSS/Module.scss";
import { CourseData } from "../../Constants/CourseData";
import Interested from "./Interested";
import industryimg from "./ModulesImages/industry360.jpg";
import Curriculum from "./ModulesInnerComponents/Curriculum";
import Instructor from "./ModulesInnerComponents/Instructor";
import FAQs from "./ModulesInnerComponents/FAQs";
import Outcome from "./ModulesInnerComponents/Outcome";
import Benefits from "./ModulesInnerComponents/Benefits";
import Overview from "./ModulesInnerComponents/Overview";
import WhoShouldAttend from "./ModulesInnerComponents/WhoShouldAttend";
import { Helmet } from "react-helmet";

// keywords = '';
// description = '';

export default function Industry() {
  const [scroll, setScroll] = useState(false);
  const heightFromTop1 = 25;

  useEffect(() => {
    const scrollFunc = () => {
      const scrolled = window.scrollY >= heightFromTop1;
      setScroll(scrolled);
    };

    window.addEventListener("scroll", scrollFunc);

    return () => {
      window.removeEventListener("scroll", scrollFunc);
    };
  }, [heightFromTop1]);

  return (
    <>
      <Helmet>
        <title>Business Analytics Course | Data Analytics Training in Gurgaon | Training and Certification in Delhi and Gurgaon</title>
        <meta
          name="description"
          content="business analytics course in gurgaon, Business analytics course in delhi, Business analytics certification course in Gurgaon, Delhi NCR India. Learn online business and Data Analytics Training in Gurgaon with PST Analytics classroom and online subscription training and certification courses In Delhi, Gurgaon, Noida and other Indian cities."
        />
        <meta
          name="keywords"
          content="data analytics training in gurgaon, Business analytics course in delhi, business analytics course in gurgaon,Training and Certification in Delhi and Gurgaon,Tableau Training, online tableau course, Tableau Training In Gurgaon, Best Tableau Training In Delhi NCR, Tableau training institute in noida, Tableau classes in Noida, Tableau Global Certification"
        />
      </Helmet>
      <div className="container-fluid course-card">
        <div className="row">
          <div
            className={
              scroll
                ? "img_containerScrolled"
                : "img_containerNormal col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9"
            }
            style={{ background: `url(${industryimg})` }}
          >
            <div className="bg-div">
              <h1 className="courseHeading">{CourseData.Industry360.Title}</h1>
              <div className="desc_">
                <p>{CourseData.Industry360.Subtitle}</p>
                <h5 className="bi bi-stopwatch">
                  {CourseData.Industry360.Time} hours
                </h5>
                <i className=""></i>
                <h5 className="bi bi-people">
                  {CourseData.Industry360.Students}
                </h5>
                <h5 className="bi bi-hand-thumbs-up">Basic to Advance</h5>
                <h5 className="bi bi-award">Global Certification</h5>
              </div>
            </div>
          </div>
          <div
            className={
              scroll
                ? "scrolledInterestedDiv col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"
                : "col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 normalInterestedDiv"
            }
          >
            <Interested />
          </div>
        </div>

        {/* Navbar */}

        <ul
          className={
            scroll
              ? "nav justify-content-center  nav-tabs container description_Nav_Scrolled"
              : "nav justify-content-center nav-tabs container description_Nav_Normal"
          }
          id="list-example"
        >
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#list-item-1">
              Overview
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-2">
              Benefits
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-3">
              Outcome
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-4">
              Curriculum
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-5">
              Instructor
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-6">
              FAQs
            </a>
          </li>
        </ul>

        {/* overview */}

        <div className="container overview_Cont">
          <div
            data-bs-spy="scroll"
            data-bs-target="#list-example"
            data-bs-offset="0"
            className="scrollspy-example"
            tabIndex="0"
          >
            <div
              id="list-item-1"
              className={scroll ? "list-item-1scrolled" : "list-item-1normal"}
            >
              <h4 style={scroll ? {} : { marginTop: "2rem" }}>
                What you'll learn
              </h4>
              <Overview
                What_YouLL_Learn={CourseData.Industry360.What_YouLL_Learn}
                Quick_Stats={CourseData.Industry360.Quick_Stats}
              />
            </div>
            <div id="list-item-2">
              <h4>Benefits</h4>
              <Benefits Benefits={CourseData.Industry360.Benefits} />
              <h4>Who Should Attend</h4>
              <WhoShouldAttend
                WhoShouldAttend={CourseData.Industry360.Who_Should_Attend}
              />
            </div>
            <div id="list-item-3">
              <h4>Course Outcome</h4>
              <Outcome Outcome={CourseData.Industry360.Course_Outcome} />
            </div>
            <div id="list-item-4">
              <h4>Curriculum</h4>
              <Curriculum Curriculum={CourseData.Industry360.Curriculum} />
            </div>
            <div id="list-item-5">
              <h4>Instructor</h4>
              <Instructor Instructors={CourseData.Industry360.Instructors} />
            </div>
            <div id="list-item-6">
              <h4>FAQs</h4>
              <FAQs FAQs={CourseData.Industry360.FAQs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
