import industry360 from "../Media/DataScience.jpg";
import dataanalytics from "../Components/Modules/ModulesImages/dataanalytics.jpg";
import machinelearning from "../Components/Modules/ModulesImages/machinelearning.jpg";
import tableu from "../Components/Modules/ModulesImages/datavisualization.jpg";
import powerbi from "../Components/Modules/ModulesImages/powerbi.jpg";
import python from "../Components/Modules/ModulesImages/python.jpg";

export const Our_Benefits = [
  {
    title: "Time-Saving",
    p: "Getting global Analytics certification means that you can manage your working schedule without any changes. You only need a PC or laptop and a couple of hours to progress with your courses. Many professionals prefer online courses to local ones as they provide more freedom in planning your day. You can contact us anytime for a consultation, therefore, you won’t have to worry about missing out.",
  },
  {
    title: "Affordable",
    p: "As is expected, the global certification preparation fee in India is much lower compared to the prices in the USA. However, it does not necessarily mean lower quality. We are devoting to helping newly certified experts emerge and do everything we can to make it happen. In addition, our global certification center often offers discounts on our modules, allowing you to save up and improve your skillset.",
  },
  {
    title: "Valid",
    p: "The GLobal Analytics certification is valid and legal not only in India & U.S. but everywhere else. After you complete the program and pass the test, SAS Institute USA gives you a certificate that proves your expertise and competence, making you the expert that any employer would love to have. You can read about the experiences of our star pupils to see, how much our courses can help your career.",
  },
];
export const Our_Acheivements = [
  {
    title: "Worldwide Students",
    number: "3500+",
  },
  {
    title: "Classes Completed",
    number: "2200+",
  },
  {
    title: "Successfull Global Certifications",
    number: "750+",
  },
  {
    title: "Students Placed",
    number: "1700+",
  },
];

export const CourseData = {
  Industry360: {
    img: industry360,
    navRoute: "industry-360",
    Title: "Industry 360",
    Subtitle: "Data Science ML Analytics AI Visualization Big Data",
    CourseFee: {
      Online: 60000,
      Instructor_Led: 60000,
    },
    Time: "400+",
    Students: "1500+",
    Instructors: [
      {
        name: "Lokesh",
        profile: "Business Leader- Data Insights",
        intro:
          "Lokesh is a Senior SAS consultant and worked with prestigious companies like Genpact, Target, Barclays, MasterCard in the past 10 years and has been working in the Data industry since the beginning of his career. He has worked for international markets as an expert in data preparation and insights in the field of Analytics. He co-founded PSTAnalytics in 2010, and has taken up the initiative to spread quality education. He has worked extensively in domains such as Retail ,Pharma , BFSI ,Healthcare,Automotive, Hi-tech, and Telecom. His day to day task include campaign management, production, Data Warehousing, ETL, and process development for batter insights and rapid delivery with precision",
      },
      {
        name: "Vivek",
        profile: "Chief Data Scientist",
        intro:
          "Vivek is a Business Consultant and worked with prestigious companies like TCS, American Express, Mu Sigma in the past 10 years and has been working in the Analytics industry since the beginning of his career. He has worked for international and domestic markets markets as an expert in Predictive modeling and forecasting role in the field of Business Analytics. He co-founded PSTAnalytics in 2010, and has taken up the initiative to spread quality education. He has worked extensively in domains such as Customer Segmentation , Market benchmarking, Customer profiling, Customer targeting, Credit card life cycle , generating Score card, Phases in clinical cycle,Market Research, Brand positioning, Building Go-to-market strategies, Pricing for clients in various sectors like BFSI, Healthcare,Automotive, Hi-tech, and Telecom. His day to day task include Credit Risk projects, propensity and attrition modeling, Credit risk score cards, Cross sell and Up sell Pre & Post campaign management.",
      },
    ],
    What_YouLL_Learn: {
      p1: "Learn Data Science ,Analytics, Machine Learning , Artificial Intelligence, by authentic Certified courses with global acclaim , Data Science using R & SAS ,Machine Learning using Python ,Analytics using Excel & SQL, Data Visualization using Tableau with PST Analytics Classroom and Online subscription Training And Certification Courses In Delhi, Gurgaon, Noida and other Indian cities.",
      p2: "Analytics is the most demanding career and global Certification the most widely authentication for Analytics knowledge. With this we combine the power of Data science with R and Machine Learning on Python for an evolving volatile market.While authentic global certification helps you in the entry for Data Science on R & Machine Learning on Python helps you grow adapt and evolve successfullly.",
      p3: "Contact us now to know more about this course.",
    },
    Quick_Stats: {
      first:
        "Analytics is now a gamechanger and every organization is investing in Big data for better growth and sustainability",
      second:
        "SAS Certification will help us enter into Analytics and build a bright future. R and Python help us adapt to new advancements towards evolution",
      third:
        "Be it experienced professional or fresher every one of us must grow with a better vision. Adapt to Analytics R and Python for a healthy growth",
    },
    Benefits: {
      first: {
        title: "Analytics on SAS Certification- Complete programme",
        p: "Knowledge of Data Science, Predictive Modeling, Machine Learning and Statistical Techniques algorithms with data driven approach.",
      },
      second: {
        title: "SAS Analytics Certification",
        p: "Industry recognized SAS Analytics-Regression and Modeling certification from SAS after completion of the program.",
      },
      third: {
        title: "Real time data driven projects",
        p: "Development of analytical and decision-making abilities by examining real-life case studies and coming up with strategies and decisions.",
      },
      fourth: {
        title: "Certification",
        p: "Certification on SAS Analytics-Regression and Modeling is a great value-add for the student’s resume, which can help an individual pursue a promising career or a career growth.",
      },
      fifth: {
        title: "Career Rise",
        p: "Looking for switch, career growth, enhancement, upskill. This is the perfect module designed for the same. Take a step forward and be a part of the future drive.",
      },
    },
    Who_Should_Attend: [
      "Engineering and IT Students – BTech / BE, BCA, MCA, BSc-IT, MSc-IT",
      "Commerce & Finance Students - BCom / MCom, Economics Graduates, MBA or BBA",
      "Highly recommended for people aspiring for jobs that required data handling – Research, Marketing, IT Services, Big Data & more",
      "People who are already employed, but want to upskill themselves in the domain of Analytics",
      "As a prerequisite, its just your passion towards data and hard work that is the only requirement, rest is just a cake-walk.",
    ],
    Course_Outcome: [
      "Understanding of basic concepts and types of data",
      "Understanding of sampling techniques",
      "Data Science and Machine Learnig concepts with application",
      "Understanding of frequency distributions and measures of central tendency, dispersion and shape",
      "In Depth Knowledge of the Hypothests testing T-Test ANOVA",
      "In depth knowledge on Correlation and Regression",
      "In depth Knowledge of Predictive modeling using Logistic Regression",
      "Two live projects which is full hands on real time industrial data",
    ],
    Curriculum: [
      {
        Step: "Getting started with Excel",
        innerDetail: [
          "1. Creating a New Workbook",
          "2. Navigating in Excel",
          "3. Moving the Cell Pointer",
          "4. Using Excel Menus",
          "5. Creating Headers, Footers, and Page Numbers",
          "6. Adjusting Page Margins and Orientation",
          "7. Adding Print Titles and Gridlines, rows to repeat at top of each page",
          "8. Formatting Fonts & Values",
          "9. Adjusting Row Height and Column Width",
          "10. Changing Cell Alignment",
          "11. Adding Borders",
        ],
      },
      {
        Step: "Switching Between Sheets in a Workbook",
        innerDetail: [
          "1. Inserting and Deleting Worksheets",
          "2. Renaming and Moving Worksheets",
          "3. Protecting a Workbook",
          "4. Hiding Columns, Rows and Sheets",
          "5. Splitting and Freezing a Window",
        ],
      },
      {
        Step: "Using Excel Toolbars: Hiding, Displaying, and Moving Toolbars",
        innerDetail: [
          "1. Entering Values in a Worksheet and Selecting a Cell Range",
          "2. Previewing and Printing a Worksheet",
          "3. Saving a Workbook & Re-opening a saved workbook",
        ],
      },
      {
        Step: "Switching Between Sheets in a Workbook",
        innerDetail: [
          "1. Splitting and Freezing a Window",
          "2. Inserting Page Breaks",
          "3. Advanced Printing Options",
        ],
      },
      {
        Step: "Entering Date Values and using AutoComplete",
        innerDetail: [
          "1. Editing, Clearing, and Replacing Cell Contents Cutting,",
          "2. Copying, and Pasting Cells Moving and Copying Cells with Drag and Drop",
          "3. Collecting and Pasting Multiple items",
          "4. Using the Paste Special Command",
        ],
      },
      {
        Step: "Inserting and Deleting Cells, Rows, and Columns",
        innerDetail: [
          "1. Creating a New Workbook",
          "2. Navigating in Excel",
          "3. Moving the Cell Pointer",
          "4. Using Excel Menus",
          "5. Creating Headers, Footers, and Page Numbers",
          "6. Adjusting Page Margins and Orientation",
          "7. Adding Print Titles and Gridlines, rows to repeat at top of each page",
          "8. Formatting Fonts & Values",
          "9. Adjusting Row Height and Column Width",
          "10. Changing Cell Alignment",
          "11. Adding Borders",
        ],
      },
      {
        Step: "Working with the Forms Menu",
        innerDetail: [
          "1. Sorting, Subtotaling & Filtering Data",
          "2. Copy & Paste Filtered Records",
          "3. Using Data Validation",
        ],
      },
      {
        Step: "Using Excel Toolbars: Hiding Displaying, and Moving Toolbars",
        innerDetail: [
          "1. Using Undo, Redo, and Repeat Checking Your Spelling",
          "2. Finding and Replacing Information",
          "3. Inserting Cell Comments",
          "4. Creating a basic Formula",
          "5. Cell Referencing",
          "6. Calculating Value Totals with AutoSum",
          "7. Editing & Copying Formulas",
          "8. Fixing Errors in Your Formulas",
          "9. Formulas with Several Operators",
          "10. Cell Ranges",
          "11. Conditional Formatting",
        ],
      },

      {
        Step: "Creating & Working with Charts",
        innerDetail: [
          "1. Creating a Chart",
          "2. Moving and Resizing a Chart",
          "3. Formatting and Editing Objects in a Chart",
          "4. Changing a Chart's Source Data",
        ],
      },
      {
        Step: "Changing a Chart Type and Working with Pie Charts",
        innerDetail: [
          "1. Adding Titles, Gridlines, and a Data Table",
          "2. Formatting a Data Series and Chart Axis",
          "3. Using Fill Effects",
        ],
      },
      {
        Step: "Data Analysis & Pivot Tables",

        innerDetail: [
          "1. Creating a PivotTable",
          "2. Specifying the Data a PivotTable Analyzes",
          "3. Changing a PivotTable's Calculation",
          "4. Selecting What Appears in a PivotTable",
          "5. Grouping Dates in a PivotTable",
          "6. Updating a PivotTable",
          "7. Formatting and Charting a PivotTable",
          "8. Automating Tasks with Macros",
          "9. Recording a Macro",
          "10. Playing a Macro and Assigning a Macro",
          "11. Shortcut Key",
        ],
      },
      {
        Step: "Basic SQL",
        innerDetail: [
          "1. Introduction to SQL and Relational Databases",
          "2. Basic SQL Syntax and Commands(SELECT, FROM, WHERE, etc.)",
          "3. Creating and Modifying Tables with DDL Statements",
          "4. DML statements(INSERT, UPDATE, DELETE)",
          "5. Filtering Data with WHERE Clause",
          "6. AND, IN, OR, NOT IN, BETWEEN, LIKE Operator",
          "7. Sorting Data with ORDER BY Clause",
          "8. Joining Tables with INNER JOIN, LEFT JOIN, RIGHT JOIN",
          "9. Union and Union All",
          "10. Grouping Data with GROUP BY Clause",
          "11. Aggregating Data with SQL Functions(COUNT, SUM, AVG, etc.)",
        ],
      },

      {
        Step: "Advanced SQL",
        innerDetail: [
          "1. Introduction to Window Functions",
          "2. Using Window Functions with OVER Clause",
          "3. Partitioning Data with PARTITION BY Clause",
          "4. Advanced Joins (OUTER JOIN, CROSS JOIN, SELF JOIN)",
          "5. Using Aliases to Simplify Queries",
          "6. Working with Date and Time Data",
          "7. Conditional Logic with CASE Statements",
          "8. Using Views",
          "9. Introduction to Stored Procedures",
          "10. Performance Tuning Techniques (query optimization)",
        ],
      },

      {
        Step: "Introduction to Data Science and Python",
        innerDetail: [
          "1. What is data science?",
          "2. Introduction to Python programming",
          "3. Jupyter Notebooks",
        ],
      },

      {
        Step: "Data Manipulation with Python",
        innerDetail: [
          "1. Introduction to NumPy",
          "2. Data manipulation with Pandas",
          "3. Exploring and cleaning datasets",
        ],
      },

      {
        Step: "Data Visualization",
        innerDetail: [
          "1. Basic plotting with Matplotlib",
          "2. Statistical data visualization with Seaborn",
          "3. Customizing plots and visualizations",
        ],
      },

      {
        Step: "Statistics and Exploratory Data Analysis",
        innerDetail: ["1. Descriptive statistics"],
      },

      {
        Step: "(EDA)",
        innerDetail: [
          "1. Hypothesis testing",
          "2. Correlation and regression analysis",
          "3. Exploratory data analysis techniques and best practices",
        ],
      },

      {
        Step: "Data Preprocessing and Feature Engineering",
        innerDetail: [
          "1. Handling missing data",
          "2. Dealing with outliers",
          "3. Feature scaling and normalization",
          "4. Feature encoding and transformation",
          "5. Feature selection techniques",
        ],
      },
      {
        Step: "Supervised Learning Algorithms",
        innerDetail: [
          "1. Linear regression",
          "2. Logistic regression",
          "3. Decision trees and random forests",
          "4. Support Vector Machines(SVM)",
          "5. k - Nearest Neighbors(k - NN)",
          "6. Naive Bayes classifiers",
        ],
      },
      {
        Step: "Model Evaluation and Selection",
        innerDetail: [
          "1. Training and testing splits",
          "2. Evaluation metrics(accuracy, precision, recall, F1 - score)",
          "3. Cross - validation techniques",
          "4. Hyperparameter tuning using grid search and random search",
          "5. Model selection and comparison",
        ],
      },
      {
        Step: "Unsupervised Learning Algorithms",
        innerDetail: [
          "1. k - Means clustering",
          "2. Hierarchical clustering",
          "3. Principal Component Analysis(PCA)",
          "4. t - SNE for high - dimensional visualization",
          "5. Association rule mining(Apriori algorithm)",
        ],
      },
      {
        Step: "Dimensionality Reduction Techniques",
        innerDetail: [
          "1. Feature extraction using PCA and NMF",
          "2. Manifold learning techniques(Isomap, LLE)",
          "3. Autoencoders for unsupervised feature learning",
        ],
      },
      {
        Step: "Ensemble Learning",
        innerDetail: [
          "1. Bagging and random forests",
          "2. Boosting techniques(AdaBoost, Gradient Boosting)",
          "3. Stacking and blending models",
          "4. XGBoost and LightGBM",
        ],
      },
      {
        Step: "Neural Networks and Deep Learning",
        innerDetail: [
          "1. Introduction to artificial neural networks",
          "2. Feedforward neural networks and backpropagation",
          "3. Convolutional Neural Networks(CNN)",
          "4. Recurrent Neural Networks(RNN) and LSTM",
          "5. Transfer learning with pre - trained models",
          "6. Generative Adversarial Networks(GANs)",
        ],
      },
      {
        Step: "Time Series Analysis and Forecasting",
        innerDetail: [
          "1. Time series data properties",
          "2. Handling seasonality and trends",
          "3. ARIMA modeling",
          "4. Exponential smoothing methods",
          "5. Prophet for time series forecasting",
          "6. Long Short - Term Memory(LSTM) networks for time series",
        ],
      },
      {
        Step: "Natural Language Processing(NLP)",
        innerDetail: [
          "1. Text preprocessing and tokenization",
          "2. Text classification techniques(Naive Bayes, SVM)",
          "3. Word embeddings(Word2Vec, GloVe)",
          "4. Recurrent Neural Networks(RNN) for text generation",
          "5. Transformers and BERT for NLP tasks",
        ],
      },
      {
        Step: "Model Deployment and Productionization",
        innerDetail: [
          "1. Saving and loading models",
          "2. Building RESTful APIs for model serving",
          "3. Containerization with Docker",
          "4. Deployment on cloud platforms(AWS, GCP, Azure)",
          "5. Monitoring and maintaining deployed models",
          "6. Model versioning and CI / CD pipelines",
        ],
      },
      {
        Step: "Model Interpretability and Explainability",
        innerDetail: [
          "1. Importance of model interpretability",
          "2. Feature importance analysis",
          "3. Model - agnostic interpretability techniques(SHAP, LIME)",
          "4. Interpretable machine learning models(decision trees, rule - based models)",
        ],
      },
      {
        Step: "Ethics and Bias in Data Science",
        innerDetail: [
          "1. Understanding bias in data and algorithms",
          "2. Fairness and transparency in machine learning",
          "3. Ethical considerations and responsible AI practices",
          "4. Mitigating bias in data and models",
        ],
      },
      {
        Step: "Basic Tableau",
        innerDetail: [
          "1. Introduction – Why data visualization",
          "2. Architecture of Tableau",
          "3. Welcome! What is Tableau & Course Overview ?",
          "4. Installation",
        ],
      },
      {
        Step: "Data connectivity and navigation",
        innerDetail: [
          "1. Connecting Tableau with different data sources",
          "2. Working with data extracts",
          "3. Navigating tableau",
        ],
      },
      {
        Step: "My first visual - Bar chart",
        innerDetail: [
          "1. Business challenge",
          "2. Creating calculated field",
          "3. Adding colors",
          "4. Adding labels and formatting",
          "5. Exporting the worksheet",
        ],
      },
      {
        Step: "Time series, aggregation and filters",
        innerDetail: [
          "1. Working with time series",
          "2. Understanding Aggregation, Granularity and level of details",
          "3. Creating area chart and learning about highlighting",
          "4. Adding quick filters",
        ],
      },
      {
        Step: "Other charts in Tableau",
        innerDetail: [
          "1. Tree map",
          "2. Bubble chart",
          "3. Bullet chart",
          "4. Pie chart",
          "5. Other visuals",
        ],
      },
      {
        Step: "Maps and scatter plot",
        innerDetail: [
          "1. Creating maps and working with hierarchies",
          "2. Creating scatter plot and applying filters in multiple sheets",
          "3. Let's create the first dashboard",
          "4. Apply action filters",
        ],
      },
      {
        Step: "Joining and Data blending",
        innerDetail: [
          "1. Understand left, right, inner, outer joins",
          "2. Joins with duplicate values",
          "3. Join data vs Blending data in Tableau",
          "4. Editing blending relation",
        ],
      },
      {
        Step: "Advanced Tableau",
        innerDetail: [
          "1. Groups and Sets",
          "2. Working with Groups",
          "3. Creating dynamic sets",
          "4. Combining sets",
          "5. Controlling sets with parameter",
        ],
      },
      {
        Step: "Advanced Table calculation",
        innerDetail: [
          "1. Creating multiple joins",
          "2. Calculated field vs Table Calculation",
          "3. Creating advanced table calculation",
          "4. Saving quick table calculation",
          "5. Specifying direction of computation",
          "6. Writing your own table calculation",
          "7. Adding second layer of moving average",
          "8. Quality assurance of table calculation",
          "9. Trend lines for power insights",
        ],
      },
      {
        Step: "Advanced data preparation + Analytics in Tableau",
        innerDetail: [
          "1. Building box plots in Tableau",
          "2. Analysing box plots",
          "3. Working with large data sources",
          "4. Pivot and split",
          "5. Trend lines",
          "6. Data prep exercise",
          "7. Advanced time series blending",
          "8. Forecasting in Tableau",
          "9. Integration of Tableau with R",
        ],
      },
      {
        Step: "Creating animations in Tableau",
        innerDetail: ["1. Adding animation in the visualization"],
      },
      {
        Step: "Level of details(LOD)",
        innerDetail: [
          "1. LOD Type 1(Include)",
          "2. Understanding ATTR in Tableau",
          "3. LOD Type 2(Exclude)",
          "4. LOD Type 3(Fixed)",
          "5. Multiple fields in LOD Calculations",
        ],
      },
      {
        Step: "Tableau Server",
        innerDetail: [
          "1. Users and Groups",
          "2. Publishing Dashboards to the server",
          "3. Difference between Tableau online and Tableau server",
        ],
      },
      {
        Step: "Power BI Introduction",
        innerDetail: [
          "1. Data Visualization, Reporting",
          "2. Business Intelligence(BI), Traditional BI, Self - Serviced BI",
          "3. Cloud Based BI, On Premise BI",
          "4. Power BI Products",
          "5. Power BI Desktop(Power Query, Power Pivot, Power View)",
          "6. Flow of Work in Power BI Desktop",
          "7. Power BI Report Server, Power BI Service, Power BI Mobile Flow",
          "8. of Work in Power BI / Power BI Architecture",
          "9. A Brief History of Power BI",
        ],
      },
      {
        Step: "Power Query",
        innerDetail: [
          "1. Data Transformation, Benefits of Data Transformation",
          "2. Shape or Transform Data using Power Query",
          "3. Overview of Power Query / Query Editor, Query Editor User Interface The",
          "4. Ribbon(Home, Transform, Add Column, View Tabs)",
          "5. The Queries Pane, The Data View / Results Pane, The Query Settings Pane, Formula Bar",
          "6. Saving the Work",
          "7. Datatypes, Changing the Datatype of a Column Filter",
          "8. in Power Query",
          "9. Auto Filter / Basic Filtering",
          "10. Filter a Column using Text Filters",
          "11. Filter a Column using Number Filters",
        ],
      },
      {
        Step: "M Language",
        innerDetail: [
          "1. IF..ELSE Conditions, Transform Column() Types",
          "2. Remove Columns(), Split Columns(), Replace Value()",
          "3. Table.Distinct Options and GROUP BY Options Table.",
          "4. Group(), Table.Sort() with Type Conversions PIVOT",
          "5. Operation and Table.Pivot().List Functions Using",
          "6. Parameters with M Language",
        ],
      },
      {
        Step: "Data Modeling",
        innerDetail: [
          "1. Data Modeling Introduction",
          "2. Relationship, Need of Relationship",
          "3. Relationship Types / Cardinality in General",
          "4. One - to - One, One - to - Many(or Many - to - One), Many - to - Many",
          "5. AutoDetect the relationship, Create a new relationship, Edit existing relationships",
          "6. Make Relationship Active or Inactive",
          "7. Delete a relationship",
        ],
      },
      {
        Step: "DAX",
        innerDetail: [
          "1. What is DAX, Calculated Column, Measures",
          "2. DAX Table and Column Name Syntax",
          "3. Creating Calculated Columns, Creating Measures Calculated",
          "4. Columns Vs Measures",
          "5. DAX Syntax & Operators",
          "6. DAX Operators",
          "7. Types of Operators",
          "8. Arithmetic Operators, Comparison Operators, Text Concatenation Operator, Logical",
          "9. Operators",
        ],
      },
      {
        Step: "DAX Functions Types",
        innerDetail: [
          "1. Date and Time Functions",
          "2. Text Functions",
          "3. Logical Functions",
          "4. Math & Statistical Functions",
          "5. Filter Functions",
        ],
      },
      {
        Step: "Time Intelligence Functions Date and Time Functions",
        innerDetail: [
          "1. YEAR, MONTH, DAY",
          "2. WEEKDAY, WEEKNUM",
          "3. FORMAT(Text Function) à Month Name, Weekday Name",
          "4. DATE, TODAY, NOW",
          "5. HOUR, MINUTE, SECOND, TIME",
          "6. DATEDIFF, CALENDAR",
          "7. Creating Date Dimension Table",
        ],
      },
      {
        Step: "Text Functions",
        innerDetail: [
          "1. LEN, CONCATENATE(&)",
          "2. LEFT, RIGHT, MID UPPER,",
          "3. LOWER",
          "4. TRIM, SUBSTITUTE, BLANK",
        ],
      },
      {
        Step: "Logical Functions",
        innerDetail: [
          "1. IF",
          "2. TRUE, FALSE NOT,",
          "3. OR, IN, AND",
          "4. IFERROR SWITCH",
        ],
      },
      {
        Step: "Math & Statistical Functions",
        innerDetail: [
          "1. INT",
          "2. ROUND, ROUNDUP, ROUNDDOWN",
          "3. DIVIDE",
          "4. EVEN, ODD",
          "5. POWER, SIGN",
          "6. SQRT, FACT",
          "7. SUM, SUMX",
          "8. MIN, MINX",
          "9. MAX, MAXX",
          "10. COUNT, COUNTX",
          "11. AVERAGE, AVERAGEX",
        ],
      },
      {
        Step: "Filter Functions",
        innerDetail: ["1. CALCULATE", "2. ALL", "3. RELATED", "4. Report View"],
      },
      {
        Step: "Report View User Interface",
        innerDetail: [
          "1. Fields Pane, Visualizations pane, Ribbon, Views, Pages Tab, Canvas Visual",
          "2. Interactions",
          "3. Interaction Type(Filter, Highlight, None)",
          "4. Visual Interactions Default Behavior, Changing the Interaction",
        ],
      },
      {
        Step: "Grouping and Binning Introduction",
        innerDetail: [
          "1. Using grouping, Creating Groups on Text Columns",
          "2. Using binning, Creating Bins on Number Column and Date Columns",
          "3. Sorting Data in Visuals",
          "4. Changing the Sort Column, Changing the Sort Order",
          "5. Sort using column that is not used in the Visualization",
          "6. Sort using the Sort by Column button",
          "7. Hierarchy Introduction, Default Date Hierarchy",
          "8. Creating Hierarchy, Creating Custom Date Hierarchy",
          "9. Change Hierarchy Levels",
          "10. Drill - Up and Drill - Down Reports",
          "11. Data Actions, Drill Down, Drill Up, Show Next Level",
        ],
      },
      {
        Step: "Visualizations",
        innerDetail: [
          "1. Visualizing Data, Why Visualizations",
          "2. Visualization types, Create and Format Bar and Column Charts",
          "3. Create and Format Stacked Bar Chart Stacked Column Chart Create",
          "4. and Format Clustered Bar Chart, Clustered Column Chart",
          "5. Create and Format 100 % Stacked Bar Chart, 100 % Stacked Column Chart Create and",
          "6. Format Pie and Donut Charts",
          "7. Create and Format Scatter Charts",
          "8. Create and Format Table Visual, Matrix Visualization",
          "9. Line and Area Charts",
          "10. Create and Format Line Chart, Area Chart, Stacked Area Chart",
          "11. Combo Charts",
          "12. Create and For",
        ],
      },
      {
        Step: "Power BI Service",
        innerDetail: [
          "1. Power BI Service Introduction, Power BI Cloud Architecture",
          "2. Creating Power BI Service Account, SIGN IN to Power BI Service Account",
          "3. Publishing Reports to the Power BI service, Import / Getting the Report to PBI Service My",
          "4. Workspace / App Workspaces Tabs",
          "5. DATASETS, WORKBOOKS, REPORTS, DASHBOARDS",
          "6. Working with Datasets, Creating Reports in Cloud using Published Datasets",
        ],
      },
      {
        Step: "Creating Dashboards",
        innerDetail: [
          "1. Pin Visuals and Pin LIVE Report Pages to Dashboard",
          "2. Advantages of Dashboards",
          "3. Interacting with Dashboards",
          "4. Formatting Dashboard",
          "5. Sharing Dashboard",
        ],
      },
      {
        Step: "Basic Concepts",
        innerDetail: [
          "1. Introduction to SAS tool",
          "2. SAS Libraries / Temporary Library / Permanent Library",
          "3. Creating Libraries",
          "4. Start with a Basic SAS programs",
          "5. Data Step / Proc Step / Statements / Global statements",
          "6. Variables / Datatypes / properties of Variables",
        ],
      },
      {
        Step: "Access Data",
        innerDetail: [
          "1. INFILE statement options to read raw data files",
          "2. Creating a file refrence with filename statement",
          "3. DATALINES statement with an INPUT statement",
        ],
      },
      {
        Step: "Starting With Raw Data(Basics)",
        innerDetail: [
          "1. Styles of Input",
          "2. Reading Unaligned Data / Understanding List Input",
          "3. Understanding Column Input / Reading Data Aligned in Columns",
        ],
      },
      {
        Step: "Formats and Informats",
        innerDetail: [
          "1. Standard Data / Non Standard Data",
          "2. How Informats and Format works",
          "3. Working with Date / Time / Datetime informat",
          "4. How and when to use Yearcutoff",
        ],
      },
      {
        Step: "Starting With Raw Data(Beyond Basics)",
        innerDetail: ["1. Formatted Input style", "2. Using Modifiers"],
      },
      {
        Step: "Mixing Styles of Input",
        innerDetail: [
          "1. Testing a Condition before Creating an Observation",
          "2. Creating Multiple Observations from a Single Record",
          "3. Reading Multiple Records to Create a Single Observation",
        ],
      },
      {
        Step: "PDV: How the DATA Step Works",
        innerDetail: [
          "1. Writing Basic Data Step",
          "2. How SAS Processes Programs",
          "3. Compilation phase",
          "4. Execution Phase",
          "5. Debugging a Data Step",
          "6. Testing SAS Programs",
        ],
      },
      {
        Step: "Manipulating SAS Datasets",
        innerDetail: [
          "1. Creating & Modifying Variables",
          "2. Assigning Values Conditionally",
          "3. Specifying Lengths for Variables",
          "4. Subsetting Data",
          "5. Assigning Permanent Labels and Formats",
        ],
      },
      {
        Step: "Grouping Statements Using DO Groups",
        innerDetail: [
          "1. Assigning Values Conditionally Using SELECT Groups",
          "2. Reading a Single Data Set",
          "3. Manipulating Data",
          "4. Using BY - Group Processing",
          "5. Reading Observations Using Direct Access(Point = option)",
          "6. Detecting the End of a Data Set(end = option)",
          "7. Understanding How Data Sets Are Read through PDV",
          "8. Renaming Variables",
          "9. Selecting Variables",
        ],
      },
      {
        Step: "Combining SAS Data Sets",
        innerDetail: [
          "1. One - to - One Reading",
          "2. Concatenating",
          "3. Interleaving",
          "4. Match - Merging",
          "5. Match - Merge Processing",
          "6. Excluding Unmatched Observations",
        ],
      },
      {
        Step: "Transforming Data with SAS Functions",
        innerDetail: [
          "1. General Form of SAS Functions",
          "2. Converting Data with Functions",
          "3. Restriction for WHERE Expressions",
          "4. Manipulating SAS Date Values with Functions",
          "5. SAS Date and Time Values",
          "6. SAS Date Functions",
          "7. Modifying Character Values with Functions",
          "8. Modifying Numeric Values with Functions",
          "9. Nesting SAS Functions",
        ],
      },
      {
        Step: "Relevant base SAS procedures",
        innerDetail: [
          "1. Append procedure",
          "2. Sort procedure",
          "3. Datasets procedure",
          "4. Printto procedure",
          "5. Format procedure",
          "6. Transpose procedure",
          "7. Import procedure",
          "8. Export procedure",
          "9. Print procedure",
          "10. Tabulate procedure",
          "11. Report procedure",
        ],
      },
      {
        Step: "Generating Data with DO Loops",
        innerDetail: [
          "1. Constructing DO Loops",
          "2. Introduction to Constructing DO Loops",
          "3. DO Loop Execution",
          "4. Counting Iterations of DO Loops",
          "5. Decrementing DO Loops",
          "6. Nesting DO Loops",
          "7. Iteratively Processing Data That Is Read from a Data Set",
          "8. Conditionally Executing DO Loops",
          "9. Using Conditional Clauses with the Iterative DO Statement",
          "10. Creating Samples",
        ],
      },
      {
        Step: "Processing Variables with Arrays",
        innerDetail: [
          "1. Creating One - Dimensional Arrays",
          "2. Understanding SAS Arrays",
          "3. Defining an Array",
          "4. Variable Lists as Array Elements",
          "5. Referencing Elements of an Array",
          "6. Compilation and Execution",
          "7. Using the DIM Function in an Iterative DO Statement",
          "8. Creating Variables in an ARRAY Statement",
          "9. Creating Temporary Array Elements",
        ],
      },
      {
        Step: "Analytics - An Introduction",
        innerDetail: [
          "1. What is Business Analytics",
          "2. Difference between Analytics and Analysis",
          "3. Importance of Analytics in Industry",
          "4. Application of Analytics in Industry",
          "5. Learning and the growth curve of Analytics",
          "6. Puzzle",
          "7. Interview Preparation",
        ],
      },
      {
        Step: "Data and Variables - An introduction",
        innerDetail: [
          "1. What is Statististics",
          "2. What is an Average - Mean Median Mode",
          "3. Different types of Data and variables",
          "4. Basic Statistical Measures",
          "5. Puzzle",
          "6. Quiz",
          "7. Coding - An Introduction",
          "8. Dependent and Independent Variable",
        ],
      },
      {
        Step: "Population and Sample - Sampling techniques",
        innerDetail: [
          "1. Why do we need sample over a population",
          "2. Difference between population and sample",
          "3. Sampling Techniques",
          "4. Simple Random Sampling",
          "5. Stratified Random Sampling",
          "6. Sampling with and without replacement",
          "7. Puzzle",
          "8. Assignment",
        ],
      },
      {
        Step: "Normal Distribution & Central Limit Theorem",
        innerDetail: [
          "1. Introduction to Bell Curve",
          "2. Deviation Vs Standard Deviation",
          "3. Variance and Standard deviation",
          "4. Outliers and their effects on basic statistical measures",
          "5. Symmetric and Asymmetric curve",
          "6. Bell Curve - Emperical Rule--Grading process",
          "7. Quiz",
        ],
      },
      {
        Step: "Exploratory data Analysis",
        innerDetail: [
          "1. Univariate anlysis vs Bivariate analysis Vs Multivariate analysis",
          "2. Decile, Percentile, Vintile, Quartile, Quantile",
          "3. Proc Univarite - Detailed",
          "4. Moments, Basis Statistical Measures, Test for Location, Quantiles, Extreme Observation",
          "5. Assignment",
          "6. Puzzle",
        ],
      },
      {
        Step: "Data Visualization",
        innerDetail: [
          "1. Box and whiskers plot",
          "2. Ranking Algorithm - Proc Rank in detail",
          "3. Outlier - Detection Removal Treatment",
          "4. Missing values - How critical are they to a data",
          "5. Missing value Removal and imputation",
          "6. Proc for missing value Treatment",
        ],
      },
      {
        Step: "Hypothesis Testing",
        innerDetail: [
          "1. Hypothests testing - What does that mean",
          "2. What is Hypothesis and what we do we test",
          "3. Null Hypothesis and Alternate Hypothesis",
          "4. Significanace level and Confidence level - P value and α value",
          "5. Decision making - Reject / Fail to Reject Null Hypothesis",
          "6. Confidence Interval",
          "7. Accuracy and Error",
          "8. Type I Error and Type II Error",
        ],
      },
      {
        Step: "Campaign Management",
        innerDetail: [
          "1. Test and Control group",
          "2. Solicit and Non Solicit",
          "3. Responder and Non Responder",
          "4. Targets and Non Targets",
          "5. Measuring Cost / Revenue / Profit of a campaign",
          "6. 2 * 2 profit / revenue contingency matrix",
          "7. Actual Vs predicted conflicts",
          "8. Accuracy Error Sensitivity Specificity",
          "9. Puzzle",
          "10. Interview preparation",
        ],
      },
      {
        Step: "One Sample T - Test",
        innerDetail: [
          "1. One Sample T - test - What is the hypothsis for this test",
          "2. One Sample T Test using proc ttest",
          "3. One Sample T Test using proc univariate",
          "4. Sides of a test - One sample - two sided, Upper tail, lower tail",
          "5. How to apply T - test to the data",
          "6. How will this help in making a decision",
          "7. Graphical interpretation of T test",
          "8. Puzzle",
          "9. Assignment",
          "10. Assumptions to a 1 sample T Test",
        ],
      },
      {
        Step: "Two sample T - test & Sides of a test",
        innerDetail: [
          "1. Difference between a one sample and a two sample T - Test",
          "2. Hypothesis for a T Test",
          "3. Proc T Test for 2 sample T Test",
          "4. Understanding the results from the statistical point of view",
          "5. Plotting the graph for visulizing T - Test",
          "6. Sides of 2 sample T Test - 2 sided, Lower tail and upper tail",
          "7. Application of 2 Sample T Test",
          "8. Puzzle",
          "9. Quiz",
          "10. Assumptions to 2 sample T - Test",
        ],
      },
      {
        Step: "Anova - Analysis of Variance",
        innerDetail: [
          "1. Why do we need Anova",
          "2. Why cant we use a 3 Sample T Test",
          "3. Hypothesis Testing for Anova",
          "4. Assumptions to Anova",
          "5. 1 way Anova vs N Way Anova",
          "6. Performing 2 sample T test using Anova",
          "7. Coefficient of determination",
          "8. Degree of Freedom",
          "9. Levenes Test and F test",
          "10. Interaction - Type 1 and Type III SS",
          "11. Balanced Vs Unbalanced design",
        ],
      },
      {
        Step: "Anova - hPost Hoc Analysis Test",
        innerDetail: [
          "1. Why apply Post - hoc Test when applying Anova",
          "2. Controlled Design experimen",
          "3. Experimental Error Rate",
          "4. Multiple Comparison Test",
          "5. Referential comparison",
          "6. Tukey and Dunnett Test - Sides of a test",
          "7. Diffogram and Control Plot",
          "8. Means Vs LS Means",
          "9. Puzzle",
          "10. Mock Test",
        ],
      },
      {
        Step: "Correlation - Different types",
        innerDetail: [
          "1. What is Correlation",
          "2. Why do need correlation analysis in any other analysis",
          "3. How to measure correlation",
          "4. Pearson and Spearman Correlation",
          "5. Correlation - Hypothesis Test",
          "6. Proc Corr",
          "7. Correlation Matrix",
          "8. Correlation - graphical representation and Interpretation",
          "9. Puzzle",
          "10. Case Study",
        ],
      },
      {
        Step: "Regression - Exploring the algorithm",
        innerDetail: [
          "1. Simple Linear Regression and Multiple Linear Regression",
          "2. Regression - Hypothesis Testing",
          "3. Degree of Freedom",
          "4. Anova table for Regression",
          "5. What is Ordinary least square",
          "6. Parameter Estimate and Intercept",
          "7. Significant and Non Significant Variables",
          "8. Removing Redundancy",
          "9. Collinearity - VIF",
          "10. Puzzle",
          "11. Assignment - Discussion",
        ],
      },
      {
        Step: "Logistic Regression - Introduction",
        innerDetail: [
          "1. Logistic Regression - An Introduction",
          "2. Logistic Regression - Need and the necessity",
          "3. Why cant we apply regression everywhere",
          "4. Algorithm to Logistic Regression",
          "5. Asssumption to Logistic Regression",
          "6. Checking the linearity amongst variables",
          "7. Checking for collinearity",
          "8. Removing Non Linearity",
          "9. Logistic Regression - Hypothesis testing",
        ],
      },
      {
        Step: "Predictive modeling using Logistic Regression",
        innerDetail: [
          "1. What are odds ratio",
          "2. Odds ratio vs probability",
          "3. Log odds",
          "4. log Vs natural Log",
          "5. Complete seperation Vs Quasi Complete Seperation",
          "6. Data Convergence",
          "7. Fischers Technique",
          "8. Creating and identifying the dependent variable",
          "9. Data preparation for model building process",
        ],
      },
      {
        Step: "Logistic Regression - Model Building",
        innerDetail: [
          "1. Sampling data for Training Validation Test",
          "2. Fine tuning Assessment and final assessment",
          "3. Out of sample validation",
          "4. Out of time validation",
          "5. Variable transformation",
          "6. Variable reduction techniques",
          "7. Variable clusting techniques",
          "8. Identifying Collinearity amongst variables",
          "9. Interpretation of Results",
        ],
      },
      {
        Step: "Logistic Regression - Measure model performance",
        innerDetail: [
          "1. Cumulative Lift Chart",
          "2. Cumulative Gain Chart",
          "3. Relative operating characteristics",
          "4. Area under the curve",
          "5. Model fit statistics",
          "6. validation statistics",
          "7. Variable selection techniques",
          "8. Significant and non significant variables",
          "9. Identifying the best variables for a model",
        ],
      },
      {
        Step: "Logistic Regression - Industry view and applications",
        innerDetail: [
          "1. Model selection techniques",
          "2. Parameter estimate and Intercept",
          "3. % Concordant, % Discordant, % ties pairs",
          "4. Calculatig C value from the statistics",
          "5. Puzzle",
          "6. Interview Preparation",
          "7. Case study",
          "8. Comparing training and validation statistics",
        ],
      },
      {
        Step: "SAS SQL 1: Essentials",
        innerDetail: [
          "1. Introducing the Structured Query Language",
          "2. Overview of the SQL procedure",
          "3. Specifying columns",
          "4. Specifying rows",
        ],
      },
      {
        Step: "Displaying Query Results",
        innerDetail: ["1. Presenting data", "2. Summarizing data"],
      },
      {
        Step: "SQL Joins",
        innerDetail: [
          "1. Introduction to SQL joins",
          "2. Inner joins",
          "3. Outer joins",
          "4. Complex SQL joins",
        ],
      },
      {
        Step: "Subqueries",
        innerDetail: ["1. Noncorrelated subqueries", "2. In - line views"],
      },
      {
        Step: "Set Operators",
        innerDetail: [
          "1. Introduction to set operators",
          "2. Union operator",
          "3. Outer Union operator",
          "4. Except operator",
          "5. Intersect operator",
        ],
      },
      {
        Step: "Creating Tables and Views",
        innerDetail: [
          "1. Creating tables with the SQL procedure",
          "2. Creating views with the SQL procedure",
        ],
      },
      {
        Step: "Advanced PROC SQL Features",
        innerDetail: [
          "1. Dictionary tables and views",
          "2. Using SQL procedure options",
          "3. Interfacing PROC SQL with the macro language",
        ],
      },
      {
        Step: "SAS Macro Language",
        innerDetail: [
          "1. Introduction",
          "2. Getting Familiar to the macro facility",
        ],
      },
      {
        Step: "Macro Variables",
        innerDetail: [
          "1. Introduction to macro variables",
          "2. Automatic macro variables",
          "3. Macro variable references",
          "4. User - defined macro variables",
          "5. Delimiting macro variable references",
          "6. Macro functions",
        ],
      },
      {
        Step: "Macro Definitions",
        innerDetail: ["1. Defining and calling a macro", "2. Macro parameters"],
      },
      {
        Step: "DATA Step and SQL Interfaces",
        innerDetail: [
          "1. Creating macro variables in the DATA step",
          "2. Indirect references to macro variables",
          "3. Creating macro variables in SQL",
        ],
      },
      {
        Step: "Macro Programs",
        innerDetail: [
          "1. Conditional processing",
          "2. Parameter validation",
          "3. Iterative processing",
          "4. Global and local symbol tables",
        ],
      },
      {
        Step: "Advanced SAS Programming Techniques",
        innerDetail: ["1. Measuring Efficiencies"],
      },
      {
        Step: "Controlling I / O Processing and Memory",
        innerDetail: [
          "1. SAS Data step processing",
          "2. Controlling I / O",
          "3. Using SAS views",
          "4. Reducing the length of numeric variables",
          "5. Compressing SAS data sets",
        ],
      },
      {
        Step: "Accessing Observations",
        innerDetail: [
          "1. Creating a sample data set",
          "2. Creating an index",
          "3. Using an index",
        ],
      },
      {
        Step: "Using DATA Step Arrays",
        innerDetail: [
          "1. Introduction to lookup techniques",
          "2. Using one - dimensional arrays",
          "3. Using multidimensional arrays",
          "4. Loading a multidimensional array from a SAS data set",
        ],
      },
      {
        Step: "Using DATA Step Hash and Hiter Objects",
        innerDetail: [
          "1. Introduction",
          "2. Using hash object methods",
          "3. Loading a hash object with data from a SAS data set",
          "4. Using the DATA step hiter object",
        ],
      },
      {
        Step: "Combining Data Horizontally",
        innerDetail: [
          "1. DATA step merges and SQL procedure joins",
          "2. Using an index to combine data",
          "3. Combining summary and detail data",
          "4. Combining data conditionally",
        ],
      },
      {
        Step: "Expert Programmer Techniques",
        innerDetail: [
          "1. Creating user - defined functions",
          "2. The experts’ FORMAT procedure",
        ],
      },
      {
        Step: "Project",
        innerDetail: ["1. Real Time Project"],
      },
    ],

    FAQs: [
      {
        Question: "Is this a module or a complete course?",
        Answer:
          "This is a complete course. Technically the course is Analytics on SAS Certification for which we cover five modules- SAS Base Certification, SAS Analytics Certification, SAS Advance Certification, R and Python",
      },
      {
        Question: "Is this a distance or online program?",
        Answer:
          "This is an instutor led workshop program, available in 2 modes – Instructor-Led and self paced.",
      },
      {
        Question:
          "What is the difference between instructor led and self paced program?",
        Answer:
          "In the self paced programme learners have to learn about the course on their own using the study materials provided to them after registration.There would be recorded videos which will be throughout this programme. In the Instructor-led version, learners will be provided assistance of faculty who will take them through the course as a part of classrom/ live webinar mode.",
      },
      {
        Question: "In case if I missed a class?",
        Answer:
          "Every class is recorded and In case you miss a class you can go through the recordings and let us know in case of doubts. You can even sit in other regular classes for the sessions that you missed",
      },
      {
        Question: "Is there any option of face-to-face classes?",
        Answer:
          "Yes, this is a classroom programme. In case you are not able able to make up for the class you can use the link to connect online through a webex link and can attend the same LIVE class without even coming to the class.",
      },
      {
        Question: "What is the duration of Analytics program?",
        Answer:
          "Its a weekend programme comprising 40 Hours. It would take 10 weeks or 2 months to cover the module in full. You just need to spend not more than 30 minutes a day to become a successful Analyst.",
      },
      {
        Question: "What are the speciality of this module?",
        Answer:
          "Our Analytics module is aligned to current industry requirements, uses latest tools and techniques and the curriculum of the course has been developed in consultation with industry practitioners.",
      },
      {
        Question: "Which certification will I be eligible for?",
        Answer:
          "You would be eligible for SAS certifcation exam conducted by SAS with global recognition. We will conduct exam prepration classes for the same and this is included as a part of the module",
      },
      {
        Question: "Does the fees also includes the SAS certification fees",
        Answer:
          "The preparation classes are complimentary and the exam needs to be booked. The exam fees is not included as a part of the programme",
      },
      {
        Question:
          "How is this SAS certification different from the certificates provided by other institute",
        Answer:
          "SAS certification is conducted by SAS and recognized globally. Upon successful completion your name appears on the website and anyone can check that. The certificates provided by institutes ae not recognized by any organizaion and hence not valid",
      },
      {
        Question: "What about the projects",
        Answer:
          "The projects are industrial projects with real time data and not the mocked one. This is not a capstone project where you actually do not get a pretty idea how things work in industry. It will be hands on and live industrial data decision making projects",
      },
    ],
  },

  DataAnalytics_usingSAS: {
    img: dataanalytics,
    navRoute: "data-analytics",
    Title: "Data Analytics using SAS",
    Subtitle: "Global Certified Analytics Course",
    CourseFee: {
      Online: 37000,
      Instructor_Led: 37000,
    },
    Time: "120+",
    Students: "1500+",
    Instructors: [
      {
        name: "Lokesh",
        profile: "Business Leader- Data Insights",
        intro:
          "Lokesh is a Senior SAS consultant and worked with prestigious companies like Genpact, Target, Barclays, MasterCard in the past 10 years and has been working in the Data industry since the beginning of his career. He has worked for international markets as an expert in data preparation and insights in the field of Analytics. He co-founded PSTAnalytics in 2010, and has taken up the initiative to spread quality education. He has worked extensively in domains such as Retail ,Pharma , BFSI ,Healthcare,Automotive, Hi-tech, and Telecom. His day to day task include campaign management, production, Data Warehousing, ETL, and process development for batter insights and rapid delivery with precision",
      },
      {
        name: "Vivek",
        profile: "Chief Data Scientist",
        intro:
          "Vivek is a Business Consultant and worked with prestigious companies like TCS, American Express, Mu Sigma in the past 10 years and has been working in the Analytics industry since the beginning of his career. He has worked for international and domestic markets markets as an expert in Predictive modeling and forecasting role in the field of Business Analytics. He co-founded PSTAnalytics in 2010, and has taken up the initiative to spread quality education. He has worked extensively in domains such as Customer Segmentation , Market benchmarking, Customer profiling, Customer targeting, Credit card life cycle , generating Score card, Phases in clinical cycle,Market Research, Brand positioning, Building Go-to-market strategies, Pricing for clients in various sectors like BFSI, Healthcare,Automotive, Hi-tech, and Telecom. His day to day task include Credit Risk projects, propensity and attrition modeling, Credit risk score cards, Cross sell and Up sell Pre & Post campaign management.",
      },
    ],
    What_YouLL_Learn: {
      p1: "Learn concepts of Analytics Predictive modeling, statistical learning, Machine learning with the hands-on application through SAS SQL R Python Excel for cracking global certification with PST Analytics, classroom training. The Global certification program provides you an edge over other course in Delhi, Noida, Gurgaon, and other cities",
      p2: "The complete Analytics certification course will be project based workshop rather than just classroom course. Basic understanding of R & Python will also be provided.",
      p3: "Contact us to know more about our Analytics certification course",
    },
    Quick_Stats: {
      first:
        "Organizations rank analytics as the #1 contributing factor for a competition driven growth and survival. SAS is currently the most demanding and the widely used tool across data industry and we cater the Global SAS certification training program.",
      second:
        "The Analytics industry is observing a steep growth while others are finding hard to survive.This is the new revolution in data science as a whole.",
      third:
        "Professionals with SAS Analytics certification make an easy platform for landing up in good role jobs. Freshers with certification are also given preference over others. Early entry in this field could take you far.",
    },
    Benefits: {
      first: {
        title: "SAS Analytics Certification",
        p: "Knowledge of Data Science, Predictive Modeling, Machine Learning and Statistical Techniques algorithms with data driven approach.",
      },
      second: {
        title: "Certification",
        p: "Certification on SAS Analytics-Regression and Modeling is a great value-add for the student’s resume, which can help an individual pursue a promising career or a career growth.",
      },
      third: {
        title: "Real time data driven projects",
        p: "Development of analytical and decision-making abilities by examining real-life case studies and coming up with strategies and decisions.",
      },
      fourth: {
        title: "Hands on model development",
        p: "Industry recognized SAS Analytics-Regression and modeling certification from SAS after completion of the program.",
      },
      fifth: {
        title: "Career Rise",
        p: "Looking for switch, career growth, enhancement, upskill. This is the perfect module designed for the same. Take a step forward and be a part of the future drive.",
      },
    },
    Who_Should_Attend: [
      "Engineering and IT Students – BTech / BE, BCA, MCA, BSc-IT, MSc-IT",
      "Commerce & Finance Students - BCom / MCom, Economics Graduates, MBA or BBA",
      "Highly recommended for people aspiring for jobs that required data handling – Research, Marketing, IT Services, Big Data & more",
      "People who are already employed, but want to upskill themselves in the domain of Analytics",
      "As a prerequisite, its just your passion towards data and hard work that is the only requirement, rest is just a cake-walk",
    ],
    Course_Outcome: [
      "Understanding of basic concepts and types of data",
      "Understanding of sampling techniques",
      "Data Science and Machine Learnig concepts with application",
      "Understanding of frequency distributions and measures of central tendency, dispersion and shape",
      "In Depth Knowledge of the Hypothests testing T-Test ANOVA",
      "In depth knowledge on Correlation and Regression",
      "In depth Knowledge of Predictive modeling using Logistic Regression",
      "Four live projects which is full hands on real time industrial data",
    ],
    Curriculum: [
      {
        Step: "Basic Concepts",
        innerDetail: [
          "1. Introduction to SAS tool",
          "2. SAS Libraries /Temporary Library/ Permanent Library",
          "3. Creating Libraries",
          "4. Start with a Basic SAS programs",
          "5. Data Step / Proc Step / Statements/ Global statements",
          "6. Variables / Datatypes / properties of Variables",
        ],
      },
      {
        Step: "Access Data",
        innerDetail: [
          "1. INFILE statement options to read raw data files",
          "2. Creating a file refrence with filename statement",
          "3. DATALINES statement with an INPUT statement",
        ],
      },
      {
        Step: "Starting With Raw Data(Basics)",
        innerDetail: [
          "1. Styles of Input",
          "2. Reading Unaligned Data / Understanding List Input",
          "3. Understanding Column Input / Reading Data Aligned in Columns",
        ],
      },
      {
        Step: "Formats and Informats",
        innerDetail: [
          "1. Standard Data/ Non Standard Data",
          "2. How Informats and Format works",
          "3. Working with Date/Time/Datetime informat",
          "4. How and when to use Yearcutoff",
        ],
      },
      {
        Step: "Starting With Raw Data( Beyond Basics)",
        innerDetail: ["1. Formatted Input style", "2. Using Modifiers"],
      },
      {
        Step: "Mixing Styles of Input",
        innerDetail: [
          "1. Testing a Condition before Creating an Observation",
          "2. Creating Multiple Observations from a Single Record",
          "3. Reading Multiple Records to Create a Single Observation",
        ],
      },
      {
        Step: "PDV: How the DATA Step Works",
        innerDetail: [
          "1. Writing Basic Data Step",
          "2. How SAS Processes Programs",
          "3. Compilation phase",
          "4. Execution Phase",
          "5. Debugging a Data Step",
          "6. Testing SAS Programs",
        ],
      },
      {
        Step: "Manipulating SAS Datasets",
        innerDetail: [
          "1. Creating & Modifying Variables",
          "2. Assigning Values Conditionally",
          "3. Specifying Lengths for Variables",
          "4. Subsetting Data",
          "5. Assigning Permanent Labels and Formats",
        ],
      },
      {
        Step: "Grouping Statements Using DO Groups",
        innerDetail: [
          "1. Assigning Values Conditionally Using SELECT Groups",
          "2. Reading a Single Data Set",
          "3. Manipulating Data",
          "4. Using BY-Group Processing",
          "5. Reading Observations Using Direct Access (Point= option)",
          "6. Detecting the End of a Data Set(end= option)",
          "7. Understanding How Data Sets Are Read through PDV",
          "8. Renaming Variables",
          "9. Selecting Variables",
        ],
      },
      {
        Step: "Combining SAS Data Sets",
        innerDetail: [
          "1. One-to-One Reading",
          "2. Concatenating",
          "3. Interleaving",
          "4. Match-Merging",
          "5. Match-Merge Processing",
          "6. Excluding Unmatched Observations",
        ],
      },
      {
        Step: "Transforming Data with SAS Functions",
        innerDetail: [
          "1. General Form of SAS Functions",
          "2. Converting Data with Functions",
          "3. Restriction for WHERE Expressions",
          "4. Manipulating SAS Date Values with Functions",
          "5. SAS Date and Time Values",
          "6. SAS Date Functions",
          "7. Modifying Character Values with Functions",
          "8. Modifying Numeric Values with Functions",
          "9. Nesting SAS Functions",
        ],
      },
      {
        Step: "Relevant base SAS procedures",
        innerDetail: [
          "1. Append procedure",
          "2. Sort procedure",
          "3. Datasets procedure",
          "4. Printto procedure",
          "5. Format procedure",
          "6. Transpose procedure",
          "7. Import procedure",
          "8. Export procedure",
          "9. Print procedure",
          "10. Tabulate procedure",
          "11. Report procedure",
        ],
      },
      {
        Step: "Generating Data with DO Loops",
        innerDetail: [
          "1. Constructing DO Loops",
          "2. Introduction to Constructing DO Loops",
          "3. DO Loop Execution",
          "4. Counting Iterations of DO Loops",
          "5. Decrementing DO Loops",
          "6. Nesting DO Loops",
          "7. Iteratively Processing Data That Is Read from a Data Set",
          "8. Conditionally Executing DO Loops",
          "9. Using Conditional Clauses with the Iterative DO Statement",
          "10. Creating Samples",
        ],
      },
      {
        Step: "Processing Variables with Arrays",
        innerDetail: [
          "1. Creating One-Dimensional Arrays",
          "2. Understanding SAS Arrays",
          "3. Defining an Array",
          "4. Variable Lists as Array Elements",
          "5. Referencing Elements of an Array",
          "6. Compilation and Execution",
          "7. Using the DIM Function in an Iterative DO Statement",
          "8. Creating Variables in an ARRAY Statement",
          "9. Creating Temporary Array Elements",
        ],
      },
      {
        Step: "Analytics-An Introduction",
        innerDetail: [
          "1. What is Business Analytics",
          "2. Difference between Analytics and Analysis",
          "3. Importance of Analytics in Industry",
          "4. Application of Analytics in Industry",
          "5. Learning and the growth curve of Analytics",
          "6. Puzzle",
          "7. Interview Preparation",
        ],
      },
      {
        Step: "Data and Variables-An introduction",
        innerDetail: [
          "1. What is Statististics",
          "2. What is an Average- Mean Median Mode",
          "3. Different types of Data and variables",
          "4. Basic Statistical Measures",
          "5. Puzzle",
          "6. Quiz",
          "7. Coding-An Introduction",
          "8. Dependent and Independent Variable",
        ],
      },
      {
        Step: "Population and Sample-Sampling techniques",
        innerDetail: [
          "1. Why do we need sample over a population",
          "2. Difference between population and sample",
          "3. Sampling Techniques",
          "4. Simple Random Sampling",
          "5. Stratified Random Sampling",
          "6. Sampling with and without replacement",
          "7. Puzzle",
          "8. Assignment",
        ],
      },
      {
        Step: "Normal Distribution & Central Limit Theorem",
        innerDetail: [
          "1. Introduction to Bell Curve",
          "2. Deviation Vs Standard Deviation",
          "3. Variance and Standard deviation",
          "4. Outliers and their effects on basic statistical measures",
          "5. Symmetric and Asymmetric curve",
          "6. Bell Curve-Emperical Rule--Grading process",
          "7. Quiz",
        ],
      },
      {
        Step: "Exploratory data Analysis",
        innerDetail: [
          "1. Univariate anlysis vs Bivariate analysis Vs Multivariate analysis",
          "2. Decile, Percentile,Vintile,Quartile,Quantile",
          "3. Proc Univarite- Detailed",
          "4. Moments , Basis Statistical Measures, Test for Location, Quantiles , Extreme Observation",
          "5. Assignment",
          "6. Puzzle",
        ],
      },
      {
        Step: "Data Visualization",
        innerDetail: [
          "1. Box and whiskers plot",
          "2. Ranking Algorithm-Proc Rank in detail",
          "3. Outlier- Detection Removal Treatment",
          "4. Missing values- How critical are they to a data",
          "5. Missing value Removal and imputation",
          "6. Proc for missing value Treatment",
        ],
      },
      {
        Step: "Hypothesis Testing",
        innerDetail: [
          "1. Hypothests testing - What does that mean",
          "2. What is Hypothesis and what we do we test",
          "3. Null Hypothesis and Alternate Hypothesis",
          "4. Significanace level and Confidence level- P value and α value",
          "5. Decision making- Reject/Fail to Reject Null Hypothesis",
          "6. Confidence Interval",
          "7. Accuracy and Error",
          "8. Type I Error and Type II Error",
        ],
      },
      {
        Step: "Campaign Management",
        innerDetail: [
          "1. Test and Control group",
          "2. Solicit and Non Solicit",
          "3. Responder and Non Responder",
          "4. Targets and Non Targets",
          "5. Measuring Cost/Revenue/Profit of a campaign",
          "6. 2*2 profit/revenue contingency matrix",
          "7. Actual Vs predicted conflicts",
          "8. Accuracy Error Sensitivity Specificity",
          "9. Puzzle",
          "10. Interview preparation",
        ],
      },
      {
        Step: "One Sample T-Test",
        innerDetail: [
          "1. One Sample T-test- What is the hypothsis for this test",
          "2. One Sample T Test using proc ttest",
          "3. One Sample T Test using proc univariate",
          "4. Sides of a test- One sample- two sided, Upper tail, lower tail",
          "5. How to apply T-test to the data",
          "6. How will this help in making a decision",
          "7. Graphical interpretation of T test",
          "8. Puzzle",
          "9. Assignment",
          "10. Assumptions to a 1 sample T Test",
        ],
      },
      {
        Step: "Two sample T-test & Sides of a test",
        innerDetail: [
          "1. Difference between a one sample and a two sample T-Test",
          "2. Hypothesis for a T Test",
          "3. Proc T Test for 2 sample T Test",
          "4. Understanding the results from the statistical point of view",
          "5. Plotting the graph for visulizing T-Test",
          "6. Sides of 2 sample T Test-2 sided, Lower tail and upper tail",
          "7. Application of 2 Sample T Test",
          "8. Puzzle",
          "9. Quiz",
          "10. Assumptions to 2 sample T-TEst",
        ],
      },
      {
        Step: "Anova-Analysis of Variance",
        innerDetail: [
          "1. Why do we need Anova",
          "2. Why cant we use a 3 Sample T TEst",
          "3. Hypothesis Testing for Anova",
          "4. Assumptions to Anova",
          "5. 1 way Anova vs N Way Anova",
          "6. Performing 2 sample T test using Anova",
          "7. Coefficient of determination",
          "8. Degree of Freedom",
          "9. Levenes Test and F test",
          "10. Interaction - Type 1 and Type III SS",
          "11. Balanced Vs Unbalanced design",
        ],
      },
      {
        Step: "Anova-hPost Hoc Analysis Test",
        innerDetail: [
          "1. Why apply Post-hoc Test when applying Anova",
          "2. Controlled Design experimen",
          "3. Experimental Error Rate",
          "4. Multiple Comparison Test",
          "5. Referential comparison",
          "6. Tukey and Dunnett Test- Sides of a test",
          "7. Diffogram and Control Plot",
          "8. Means Vs LS Means",
          "9. Puzzle",
          "10. Mock Test",
        ],
      },
      {
        Step: "Correlation-Different types",
        innerDetail: [
          "1. What is Correlation",
          "2. Why do need correlation analysis in any other analysis",
          "3. How to measure correlation",
          "4. Pearson and Spearman Correlation",
          "5. Correlation-Hypothesis Test",
          "6. Proc Corr",
          "7. Correlation Matrix",
          "8. Correlation-graphical representation and Interpretation",
          "9. Puzzle",
          "10. Case Study",
        ],
      },
      {
        Step: "Regression -Exploring the algorithm",
        innerDetail: [
          "1. Simple Linear Regression and Multiple Linear Regression",
          "2. Regression-Hypothesis Testing",
          "3. Degree of Freedom",
          "4. Anova table for Regression",
          "5. What is Ordinary least square",
          "6. Parameter Estimate and Intercept",
          "7. Significant and Non Significant Variables",
          "8. Removing Redundancy",
          "9. Collinearity- VIF",
          "10. Puzzle",
          "11. Assignment-Discussion",
        ],
      },
      {
        Step: "Regression - Model Building",
        innerDetail: [
          "1. Regression- Model Building",
          "2. R square and adjusted R square",
          "3. 2 way honest assessment",
          "4. 3 way honest assessment",
          "5. How to split Training validation Test",
          "6. Oversampling Undersampling Overfitting Underfitting",
          "7. Model Selection techniques",
          "8. variable selection techniques",
          "9. Model building Vs Model Fitting",
          "10. Model fit statistics",
          "11. Puzzle",
        ],
      },
      {
        Step: "Logistic Regression-Introduction",
        innerDetail: [
          "1. Logistic Regression-An Introduction",
          "2. Logistic Regression-Need and the necessity",
          "3. Why cant we apply regression everywhere",
          "4. Algorithm to Logistic Regression",
          "5. Asssumption to Logistic Regression",
          "6. Checking the linearity amongst variables",
          "7. Checking for collinearity",
          "8. Removing Non Linearity",
          "9. Logistic Regression- Hypothesis testing",
        ],
      },
      {
        Step: "Predictive modeling using Logistic Regression",
        innerDetail: [
          "1. What are odds ratio",
          "2. Odds ratio vs probability",
          "3. Log odds",
          "4. log Vs natural Log",
          "5. Complete seperation Vs Quasi Complete Seperation",
          "6. Data Convergence",
          "7. Fischers Technique",
          "8. Creating and identifying the dependent variable",
          "9. Data preparation for model building process",
        ],
      },
      {
        Step: "Logistic Regression-Model Building",
        innerDetail: [
          "1. Sampling data for Training Validation Test",
          "2. Fine tuning Assessment and final assessment",
          "3. Out of sample validation",
          "4. Out of time validation",
          "5. Variable transformation",
          "6. Variable reduction techniques",
          "7. Variable clusting techniques",
          "8. Identifying Collinearity amongst variables",
          "9. Interpretation of Results",
        ],
      },
      {
        Step: "Logistic Regression-Measure model performance",
        innerDetail: [
          "1. Cumulative Lift Chart",
          "2. Cumulative Gain Chart",
          "3. Relative operating characteristics",
          "4. Area under the curve",
          "5. Model fit statistics",
          "6. validation statistics",
          "7. Variable selection techniques",
          "8. Significant and non significant variables",
          "9. Identifying the best variables for a model",
        ],
      },
      {
        Step: "Logistic Regression-Industry view and applications",
        innerDetail: [
          "1. Model selection techniques",
          "2. Parameter estimate and Intercept",
          "3. % Concordant, %Discordant, %ties pairs",
          "4. Calculatig C value from the statistics",
          "5. Puzzle",
          "6. Interview Preparation",
          "7. Case study",
          "8. Comparing training and validation statistics",
        ],
      },
      {
        Step: "SAS SQL 1: Essentials",
        innerDetail: [
          "1. Introducing the Structured Query Language",
          "2. Overview of the SQL procedure",
          "3. Specifying columns",
          "4. Specifying rows",
        ],
      },
      {
        Step: "Displaying Query Results",
        innerDetail: ["1. Presenting data", "2. Summarizing data"],
      },
      {
        Step: "SQL Joins",
        innerDetail: [
          "1. Introduction to SQL joins",
          "2. Inner joins",
          "3. Outer joins",
          "4. Complex SQL joins",
        ],
      },
      {
        Step: "Subqueries",
        innerDetail: ["1. Noncorrelated subqueries", "2. In-line views"],
      },
      {
        Step: "Set Operators",
        innerDetail: [
          "1. Introduction to set operators",
          "2. Union operator",
          "3. Outer Union operator",
          "4. Except operator",
          "5. Intersect operator",
        ],
      },
      {
        Step: "Creating Tables and Views",
        innerDetail: [
          "1. Creating tables with the SQL procedure",
          "2. Creating views with the SQL procedure",
        ],
      },
      {
        Step: "Advanced PROC SQL Features",
        innerDetail: [
          "1. Dictionary tables and views",
          "2. Using SQL procedure options",
          "3. Interfacing PROC SQL with the macro language",
        ],
      },
      {
        Step: "SAS Macro Language",
        innerDetail: [
          "1. Introduction",
          "2. Getting Familiar to the macro facility",
        ],
      },
      {
        Step: "Macro Variables",
        innerDetail: [
          "1. Introduction to macro variables",
          "2. Automatic macro variables",
          "3. Macro variable references",
          "4. User-defined macro variables",
          "5. Delimiting macro variable references",
          "6. Macro functions",
        ],
      },
      {
        Step: "Macro Definitions",
        innerDetail: ["1. Defining and calling a macro", "2. Macro parameters"],
      },
      {
        Step: "DATA Step and SQL Interfaces",
        innerDetail: [
          "1. Creating macro variables in the DATA step",
          "2. Indirect references to macro variables",
          "3. Creating macro variables in SQL",
        ],
      },
      {
        Step: "Macro Programs",
        innerDetail: [
          "1. Conditional processing",
          "2. Parameter validation",
          "3. Iterative processing",
          "4. Global and local symbol tables",
        ],
      },
      {
        Step: "Advanced SAS Programming Techniques",
        innerDetail: ["1. Measuring Efficiencies"],
      },
      {
        Step: "Controlling I/O Processing and Memory",
        innerDetail: [
          "1. SAS Data step processing",
          "2. Controlling I/O",
          "3. Using SAS views",
          "4. Reducing the length of numeric variables",
          "5. Compressing SAS data sets",
        ],
      },
      {
        Step: "Accessing Observations",
        innerDetail: [
          "1. Creating a sample data set",
          "2. Creating an index",
          "3. Using an index",
        ],
      },
      {
        Step: "Using DATA Step Arrays",
        innerDetail: [
          "1. Introduction to lookup techniques",
          "2. Using one-dimensional arrays",
          "3. Using multidimensional arrays",
          "4. Loading a multidimensional array from a SAS data set",
        ],
      },
      {
        Step: "Using DATA Step Hash and Hiter Objects",
        innerDetail: [
          "1. Introduction",
          "2. Using hash object methods",
          "3. Loading a hash object with data from a SAS data set",
          "4. Using the DATA step hiter object",
        ],
      },
      {
        Step: "Combining Data Horizontally",
        innerDetail: [
          "1. DATA step merges and SQL procedure joins",
          "2. Using an index to combine data",
          "3. Combining summary and detail data",
          "4. Combining data conditionally",
        ],
      },
      {
        Step: "Expert Programmer Techniques",
        innerDetail: [
          "1. Creating user-defined functions",
          "2. The experts’ FORMAT procedure",
        ],
      },
      {
        Step: "Project",
        innerDetail: ["1. Real Time Project"],
      },
    ],
    FAQs: [
      {
        Question: "Is this a module or a complete course?",
        Answer:
          "This is a complete SAS certification course. Technically this is a certification course for Analytics on SAS for which we cover three modules- SAS Base Certification, SAS Analytics Certification, SAS Advance Certification",
      },
      {
        Question: "Is this a distance or online program?",
        Answer:
          "This SAS certification course is an online program, available in 2 modes – Instructor-Led and self-paced. The SAS certification course is also available with classroom training in Gurgaon.",
      },
      {
        Question:
          "What is the difference between instructor led and self paced program?",
        Answer:
          "In the self-paced programme, learners have to learn about the SAS global certification course on their own using the study materials provided to them after registration. There would be recorded videos which will be throughout this programme. In the Instructor-led version, learners will be provided assistance by faculty who will take them through the complete course as a part of classroom/ live webinar mode.",
      },
      {
        Question: "In case if I missed a class?",
        Answer:
          "Every class is recorded, and in case you miss a class you can go through the recordings and let us know if you have any doubts. You can even sit in other regular SAS certification classes at Delhi Gurgaon or Noida, for the sessions that you missed.",
      },
      {
        Question: "Is there any option of face-to-face classes?",
        Answer:
          "Yes, this is a classroom programme. In case you are not able to make up for the class you can use the webex link to connect online and attend the same LIVE class without even coming to the SAS certification class.",
      },
      {
        Question: "What is the duration of Analytics program?",
        Answer:
          "It is a weekend programme comprising of 130 Hours. It would take 12 weeks or 3 months to cover the SAS training course in full. Just by spending 30 minutes a day you can become a successful SAS certified Analyst.",
      },
      {
        Question: "What are the speciality of this module?",
        Answer:
          "Our Analytics on SAS certification course is aligned to current industry requirements, uses latest tools and techniques and the curriculum of the course has been developed in consultation with industry practitioners.",
      },
      {
        Question: "Which certification will I be eligible for?",
        Answer:
          "You would be eligible for SAS certification exam conducted by SAS with global recognition. We will conduct exam preparation classes in Delhi, Noida and Gurgaon for the same, and this is included as a part of this course",
      },
    ],
  },

  Python: {
    img: python,
    navRoute: "python",
    Title: "Python",
    Subtitle: "Python Course",
    CourseFee: {
      Online: 25000,
      Instructor_Led: 25000,
    },
    Time: "80+",
    Students: "1200+",
    Instructors:[
      {
        name:"Akashdeep"
        , profile:"Senior consultant Machine Learning"
        , intro:"Akashdeep is a Business Consultant and worked with prestigious companies like TCS, American Express, AON in the past 10 years and has been working in the Analytics industry since the beginning of his career. He has worked for international and domestic markets as an expert in Predictive modeling and forecasting role in the field of Business Analytics"
      }
    ],
    What_YouLL_Learn: {
      p1: "Learn SQL & Python Programming With PST Analytics Classroom and Online SQL & Python Training And Certification Courses In Delhi, Gurgaon, Noida, and other Indian cities.",
      p2: "SQL as a Tool is very powerful and easy to apply when it comes to complex situations. Machine learning -Python provides techniques as a solution to tough challenges The Industry is making a shift toward evolving technology and early birds get a batter chance of upward movement in the success ladder.",
      p3: "Contact us now to know more about the best Python institute In Delhi, Gurgaon Noida, and other Indian cities.",
    },
    Quick_Stats: {
      first:
        "Analytics industry is a volatile industry and picks up fast on technology. We have seen an upward trend for SQL and Python as a skill set in combination with SAS as a tool & Certification",
      second:
        "The universities and the start-ups are adapting to SQL and Python. Hence a huge scope in research-oriented jobs as a practitioner.",
      third:
        "There is a dearth of data scientist across the globe where R & Python as a skill set is picking up fast to fill up the gap. Early entries will scoop big is the prediction of the Data Pundits",
    },
    Benefits: {
      first: {
        title: "SQL + Python as a tool",
        p: "SQL as a tool is the major component of today's data science. Python training along with fills the gap for the machine and deep learning. This is an added upskill in the skill list and will help you up the success ladder",
      },
      second: {
        title: "SQL- Data Science Python - Machine Learning",
        p: "Data Scientists are betting heavy on SQL as a soul ingredient for data science. Machine Learning is in these days for Python. The world is moving away from contemporary languages to data-driven languages and SQL, Python is holding a good place as far as data science is concerned.",
      },
      third: {
        title: "Project Driven approach",
        p: "There will be no run through ppt and decks as we don't believe in such methods of learning and spreading education. It would be hands-on and classes would actually be workshops with more focus on the application part",
      },
    },
    Who_Should_Attend: [
      "Final Year Students who are looking for research-oriented jobs curriculum abroad",
      "Freshers out of the campus who are looking for jobs in start-ups",
      "Experienced folks who are in the data industry and look forward to adapting SQL, Python",
      "Experienced professionals who look forward to switching the jobs/domain to data science & Machine Learning",
    ],
    Course_Outcome: [
      "Understand the outlining features of SQL, Python",
      "Strong foundation with more hold on basics",
      "Data Extraction and Analysis with Machine Learning",
      "In-depth knowledge of SQL & Python as a tool and the applied language",
      "Proper application as to where and where not to apply",
    ],
    Curriculum: [
      {
        Step: "Introduction to Python",
        innerDetail: [
          "1. Installing Python and necessary libraries (e.g., Anaconda)",
          "2. Running Python code (interpreters, IDEs)",
          "3. Basic syntax and data types (numbers, strings, lists, dictionaries)",
        ],
      },
      {
        Step: "Control Flow and Functions",
        innerDetail: [
          "1. Conditional statements (if, elif, else)",
          "2. Loops (for, while)",
          "3. Functions (defining, calling, parameters, return values)",
        ],
      },
      {
        Step: "Python Data Structures",
        innerDetail: [
          "1. Lists, tuples, and sets",
          "2. Dictionaries and their applications",
          "3. Working with arrays (NumPy)",
        ],
      },
      {
        Step: "File Handling and Input/Output",
        innerDetail: [
          "1. Reading from and writing to files",
          "2. CSV, JSON, and other common file formats",
          "3. Standard input and output",
        ],
      },
      {
        Step: "Introduction to Statistics",
        innerDetail: [
          "1. Descriptive statistics (mean, median, standard deviation)",
          "2. Probability distributions (normal, binomial)",
          "3. Hypothesis testing (t-tests, chi-square tests)",
        ],
      },
      {
        Step: "Data Manipulation with Pandas",
        innerDetail: [
          "1. Introduction to pandas library",
          "2. Loading and exploring data",
          "3. Data cleaning, filtering, and transformation",
          "4. Aggregation and grouping operations",
        ],
      },
      {
        Step: "Data Visualization with Matplotlib and Seaborn",
        innerDetail: [
          "1. Creating basic plots (line, scatter, bar)",
          "2. Customizing plots (labels, titles, colors)",
          "3. Creating subplots and advanced visualizations",
          "4. Introduction to Seaborn for statistical visualizations",
        ],
      },
      {
        Step: "Exploratory Data Analysis (EDA)",
        innerDetail: [
          "1. Visualizing data distributions",
          "2. Handling missing data",
          "3. Feature engineering and transformation",
          "4. Correlation analysis",
        ],
      },
      {
        Step: "Basic SQL",
        innerDetail: [
          "1. Introduction to SQL and Relational Databases",
          "2. Basic SQL Syntax and Commands(SELECT, FROM, WHERE, etc.)",
          "3. Creating and Modifying Tables with DDL Statements",
          "4. DML statements(INSERT, UPDATE, DELETE)",
          "5. Filtering Data with WHERE Clause",
          "6. AND, IN, OR, NOT IN, BETWEEN, LIKE Operator",
          "7. Sorting Data with ORDER BY Clause",
          "8. Joining Tables with INNER JOIN, LEFT JOIN, RIGHT JOIN",
          "9. Union and Union All",
          "10. Grouping Data with GROUP BY Clause",
          "11. Aggregating Data with SQL Functions(COUNT, SUM, AVG, etc.)",
        ],
      },
      {
        Step: "Advanced SQL",
        innerDetail: [
          "1. Introduction to Window Functions",
          "2. Using Window Functions with OVER Clause",
          "3. Partitioning Data with PARTITION BY Clause",
          "4. Advanced Joins (OUTER JOIN, CROSS JOIN, SELF JOIN)",
          "5. Using Aliases to Simplify Queries",
          "6. Working with Date and Time Data",
          "7. Conditional Logic with CASE Statements",
          "8. Using Views",
          "9. Introduction to Stored Procedures",
          "10. Performance Tuning Techniques (query optimization)",
        ],
      },
      {
        Step: "Project",
        innerDetail: ["1. Real Time Project"],
      },
    ],
    FAQs: [
      {
        Question: "Is this a classroom programme?",
        Answer: "Yes it is.",
      },
    ],
  },

  Machine_Learning_And_AI_Using_Python: {
    img: machinelearning,
    navRoute: "machine-learning",
    Title: "Machine Learning and AI using Python",
    Subtitle: "Machine Learning Deep Learning Clustering Decision Trees",
    CourseFee: {
      Online: 30000,
      Instructor_Led: 30000,
    },
    Time: "120+",
    Students: "1500+",
    What_YouLL_Learn: {
      p1: "Learn concepts of Predictive modeling, statistical learning, Machine learning with with a strong emphasis on Python. This comprehensive course offers hands-on application through Python and SQL for cracking global certification with PST Analytics, classroom training. The Global certification program provides you an edge over other course in Delhi, Noida, Gurgaon, and other cities.",
      p2: "The complete certification course will be project based workshop rather than just classroom course. Basic understanding of SQL & Python will also be provided.",
      p3: "Contact us to know more about our Analytics certification course.",
    },
    Instructors: [
      {
        name: "Lokesh",
        profile: "Business Leader- Data Insights",
        intro:
          "Lokesh is a Senior SAS consultant and worked with prestigious companies like Genpact, Target, Barclays, MasterCard in the past 10 years and has been working in the Data industry since the beginning of his career. He has worked for international markets as an expert in data preparation and insights in the field of Analytics. He co-founded PSTAnalytics in 2010, and has taken up the initiative to spread quality education. He has worked extensively in domains such as Retail ,Pharma , BFSI ,Healthcare,Automotive, Hi-tech, and Telecom. His day to day task include campaign management, production, Data Warehousing, ETL, and process development for batter insights and rapid delivery with precision",
      },
      {
        name: "Vivek",
        profile: "Chief Data Scientist",
        intro:
          "Vivek is a Business Consultant and worked with prestigious companies like TCS, American Express, Mu Sigma in the past 10 years and has been working in the Analytics industry since the beginning of his career. He has worked for international and domestic markets markets as an expert in Predictive modeling and forecasting role in the field of Business Analytics. He co-founded PSTAnalytics in 2010, and has taken up the initiative to spread quality education. He has worked extensively in domains such as Customer Segmentation , Market benchmarking, Customer profiling, Customer targeting, Credit card life cycle , generating Score card, Phases in clinical cycle,Market Research, Brand positioning, Building Go-to-market strategies, Pricing for clients in various sectors like BFSI, Healthcare,Automotive, Hi-tech, and Telecom. His day to day task include Credit Risk projects, propensity and attrition modeling, Credit risk score cards, Cross sell and Up sell Pre & Post campaign management.",
      },
    ],
    Quick_Stats: {
      first:
        "Organizations rank analytics as the #1 contributing factor for a competition driven growth and survival. SAS is currently the most demnading and the widely used tool across data industry and we cater the Global SAS certification training program.",
      second:
        "The Analytics industry is observing a steep growth while others are finding hard to survive.This is the new revolution in data science as a whole",
      third:
        "Professionals with SAS Analytics certification make an easy platform for landing up in good role jobs. Freshers with certification are also given preference over others. Early entry in this field could take you far.",
    },
    Benefits: {
      first: {
        title: "SAS Analytics Certification",
        p: "Knowledge of Data Science, Predictive Modeling, Machine Learning and Statistical Techniques algorithms with data driven approach",
      },
      second: {
        title: "Certification",
        p: "Certification on SAS Analytics-Regression and Modeling is a great value-add for the student’s resume, which can help an individual pursue a promising career or a career growth",
      },
      third: {
        title: "Real time data driven projects",
        p: "Development of analytical and decision-making abilities by examining real-life case studies and coming up with strategies and decisions",
      },
      fourth: {
        title: "Hands on model development",
        p: "Industry recognized SAS Analytics-Regression and modeling certification from SAS after completion of the program.",
      },
      fifth: {
        title: "Career Rise",
        p: "Looking for switch, career growth, enhancement, upskill. This is the perfect module designed for the same. Take a step forward and be a part of the future drive",
      },
    },
    Who_Should_Attend: [
      "Engineering and IT Students – BTech / BE, BCA, MCA, BSc-IT, MSc-IT",
      "Commerce & Finance Students - BCom / MCom, Economics Graduates, MBA or BBA",
      "Highly recommended for people aspiring for jobs that required data handling – Research, Marketing, IT Services, Big Data & more",
      "People who are already employed, but want to upskill themselves in the domain of Analytics",
      "As a prerequisite, its just your passion towards data and hard work that is the only requirement, rest is just a cake-walk",
    ],
    Course_Outcome: [
      "Understanding of basic concepts and types of data",
      "Understanding of sampling techniques",
      "Data Science and Machine Learnig concepts with application",
      "Understanding of frequency distributions and measures of central tendency, dispersion and shape",
      "In Depth Knowledge of the Hypothests testing T-Test ANOVA",
      "In depth knowledge on Correlation and Regression",
      "In depth Knowledge of Predictive modeling using Logistic Regression",
      "Four live projects which is full hands on real time industrial data",
    ],

    Curriculum: [
      {
        Step: "Introduction to Data Science and Python",
        innerDetail: [
          "1. What is data science?",
          "2. Introduction to Python programming",
          "3. Jupyter Notebooks",
        ],
      },

      {
        Step: "Data Manipulation with Python",
        innerDetail: [
          "1. Introduction to NumPy",
          "2. Data manipulation with Pandas",
          "3. Exploring and cleaning datasets",
        ],
      },
      {
        Step: "Data Visualization",
        innerDetail: [
          "1. Basic plotting with Matplotlib",
          "2. Statistical data visualization with Seaborn",
          "3. Customizing plots and visualizations",
        ],
      },
      {
        Step: "Statistics and Exploratory Data Analysis",
        innerDetail: ["1. Descriptive statistics"],
      },
      {
        Step: "(EDA)",
        innerDetail: [
          "1. Hypothesis testing",
          "2. Correlation and regression analysis",
          "3. Exploratory data analysis techniques and best practices",
        ],
      },
      {
        Step: "Data Preprocessing and Feature Engineering",
        innerDetail: [
          "1. Handling missing data",
          "2. Dealing with outliers",
          "3. Feature scaling and normalization",
          "4. Feature encoding and transformation",
          "5. Feature selection techniques",
        ],
      },
      {
        Step: "Supervised Learning Algorithms",
        innerDetail: [
          "1. Linear regression",
          "2. Logistic regression",
          "3. Decision trees and random forests",
          "4. Support Vector Machines (SVM)",
          "5. k-Nearest Neighbors (k-NN)",
          "6. Naive Bayes classifiers",
        ],
      },
      {
        Step: "Model Evaluation and Selection",
        innerDetail: [
          "1. Training and testing splits",
          "2. Evaluation metrics (accuracy, precision, recall, F1-score)",
          "3. Cross-validation techniques",
          "4. Hyperparameter tuning using grid search and random search",
          "5. Model selection and comparison",
        ],
      },
      {
        Step: "Unsupervised Learning Algorithms",
        innerDetail: [
          "1. k-Means clustering",
          "2. Hierarchical clustering",
          "3. Principal Component Analysis (PCA)",
          "4. t-SNE for high-dimensional visualization",
          "5. Association rule mining (Apriori algorithm)",
        ],
      },
      {
        Step: "Dimensionality Reduction Techniques",
        innerDetail: [
          "1. Feature extraction using PCA and NMF",
          "2. Manifold learning techniques (Isomap, LLE)",
          "3. Autoencoders for unsupervised feature learning",
        ],
      },
      {
        Step: "Ensemble Learning",
        innerDetail: [
          "1. Bagging and random forests",
          "2. Boosting techniques (AdaBoost, Gradient Boosting)",
          "3. Stacking and blending models",
          "4. XGBoost and LightGBM",
        ],
      },
      {
        Step: "Neural Networks and Deep Learning",
        innerDetail: [
          "1. Introduction to artificial neural networks",
          "2. Feedforward neural networks and backpropagation",
          "3. Convolutional Neural Networks (CNN)",
          "4. Recurrent Neural Networks (RNN) and LSTM",
          "5. Transfer learning with pre-trained models",
          "6. Generative Adversarial Networks (GANs)",
        ],
      },
      {
        Step: "Time Series Analysis and Forecasting",
        innerDetail: [
          "1. Time series data properties",
          "2. Handling seasonality and trends",
          "3. ARIMA modeling",
          "4. Exponential smoothing methods",
          "5. Prophet for time series forecasting",
          "6. Long Short-Term Memory (LSTM) networks for time series",
        ],
      },
      {
        Step: "Natural Language Processing (NLP)",
        innerDetail: [
          "1. Text preprocessing and tokenization",
          "2. Text classification techniques (Naive Bayes, SVM)",
          "3. Word embeddings (Word2Vec, GloVe)",
          "4. Recurrent Neural Networks (RNN) for text generation",
          "5. Transformers and BERT for NLP tasks",
        ],
      },
      {
        Step: "Model Deployment and Productionization",
        innerDetail: [
          "1. Saving and loading models",
          "2. Building RESTful APIs for model serving",
          "3. Containerization with Docker",
          "4. Deployment on cloud platforms (AWS, GCP, Azure)",
          "5. Monitoring and maintaining deployed models",
          "6. Model versioning and CI/CD pipelines",
        ],
      },
      {
        Step: "Model Interpretability and Explainability",
        innerDetail: [
          "1. Importance of model interpretability",
          "2. Feature importance analysis",
          "3. Model-agnostic interpretability techniques (SHAP, LIME)",
          "4. Interpretable machine learning models (decision trees, rule-based models)",
        ],
      },
      {
        Step: "Ethics and Bias in Data Science",
        innerDetail: [
          "1. Understanding bias in data and algorithms",
          "2. Fairness and transparency in machine learning",
          "3. Ethical considerations and responsible AI practices",
          "4. Mitigating bias in data and models",
        ],
      },
      {
        Step: "Basic SQL",
        innerDetail: [
          "1. Introduction to SQL and Relational Databases",
          "2. Basic SQL Syntax and Commands(SELECT, FROM, WHERE, etc.)",
          "3. Creating and Modifying Tables with DDL Statements",
          "4. DML statements(INSERT, UPDATE, DELETE)",
          "5. Filtering Data with WHERE Clause",
          "6. AND, IN, OR, NOT IN, BETWEEN, LIKE Operator",
          "7. Sorting Data with ORDER BY Clause",
          "8. Joining Tables with INNER JOIN, LEFT JOIN, RIGHT JOIN",
          "9. Union and Union All",
          "10. Grouping Data with GROUP BY Clause",
          "11. Aggregating Data with SQL Functions(COUNT, SUM, AVG, etc.)",
        ],
      },
      {
        Step: "Advanced SQL",
        innerDetail: [
          "1. Introduction to Window Functions",
          "2. Using Window Functions with OVER Clause",
          "3. Partitioning Data with PARTITION BY Clause",
          "4. Advanced Joins (OUTER JOIN, CROSS JOIN, SELF JOIN)",
          "5. Using Aliases to Simplify Queries",
          "6. Working with Date and Time Data",
          "7. Conditional Logic with CASE Statements",
          "8. Using Views",
          "9. Introduction to Stored Procedures",
          "10. Performance Tuning Techniques (query optimization)",
        ],
      },
      {
        Step: "Project",
        innerDetail: ["1. Real Time Project"],
      },
    ],
    FAQs: [
      {
        Question: "Is this a module or a complete course?",
        Answer:
          "This is a complete SAS certification course. Technically this is a certification course for Analytics on SAS for which we cover three modules- SAS Base Certification, SAS Analytics Certification, SAS Advance Certification",
      },
      {
        Question: "Is this a distance or online program?",
        Answer:
          "This SAS certification course is an online program, available in 2 modes – Instructor-Led and self-paced. The SAS certification course is also available with classroom training in Gurgaon.",
      },
      {
        Question:
          "What is the difference between instructor led and self paced program?",
        Answer:
          "In the self-paced programme, learners have to learn about the SAS global certification course on their own using the study materials provided to them after registration. There would be recorded videos which will be throughout this programme. In the Instructor-led version, learners will be provided assistance by faculty who will take them through the complete course as a part of classroom/ live webinar mode.",
      },
      {
        Question: "In case if I missed a class?",
        Answer:
          "Every class is recorded, and in case you miss a class you can go through the recordings and let us know if you have any doubts. You can even sit in other regular SAS certification classes at Delhi Gurgaon or Noida, for the sessions that you missed.",
      },
      {
        Question: "Is there any option of face-to-face classes?",
        Answer:
          "Yes, this is a classroom programme. In case you are not able to make up for the class you can use the webex link to connect online and attend the same LIVE class without even coming to the SAS certification class.",
      },
      {
        Question: "What is the duration of Analytics program?",
        Answer:
          "It is a weekend programme comprising of 130 Hours. It would take 12 weeks or 3 months to cover the SAS training course in full. Just by spending 30 minutes a day you can become a successful SAS certified Analyst.",
      },
      {
        Question: "What are the speciality of this module?",
        Answer:
          "Our Analytics on SAS certification course is aligned to current industry requirements, uses latest tools and techniques and the curriculum of the course has been developed in consultation with industry practitioners.",
      },
      {
        Question: "Which certification will I be eligible for?",
        Answer:
          "You would be eligible for SAS certification exam conducted by SAS with global recognition. We will conduct exam preparation classes in Delhi, Noida and Gurgaon for the same, and this is included as a part of this course",
      },
      {
        Question: "Does the fees also includes the SAS certification fees?",
        Answer:
          "The SAS certification training and preparation classes are the salient aspects of this course which is not offered anywhere but PST . We make sure that the concepts are well tutored for you to be successful in global exam.",
      },
      {
        Question:
          "How is this SAS certification different from the certificates provided by other institute?",
        Answer:
          "SAS certification is conducted by SAS and recognized globally. Upon successful completion your name appears on the website and anyone can check that. The certificates provided by local institutes ae not recognized by any organization and hence not valid anywhere",
      },
      {
        Question:
          "Do I have to pay the re-exam fee, if I’m unable to pass in first attempt?",
        Answer:
          "We guarantee this would not be the case. Untill now there has been no case like this. But if that happens we will refund the full fees. You could be a lucky one and stand a chance for the same. You have to be amongst odd to become one of this kind.",
      },
      {
        Question: "What about the projects?",
        Answer:
          "The projects which are four in number are industrial projects with real time data and not the mocked one. These are not a capstone project where you actually do not get a pretty idea how things work in industry. It will be hands on and live",
      },
    ],
  },

  Data_Visualization_Tableu: {
    img: tableu,
    navRoute: "data-visualization",
    Title: "Data Visualization - Tableu",
    Subtitle: "Data Visualization Course & Training",
    CourseFee: {
      Online: 15000,
      Instructor_Led: 15000,
    },
    Time: "40+",
    Students: "1000+",
    What_YouLL_Learn: {
      p1: "Learn Data Visualization concepts with Tableau hands-on at PST Analytics Classroom and Online Tableau Training And Certification Courses In Delhi, Gurgaon, Noida and other Indian cities.",
      p2: "Tableau is a very powerful and easy to understand data visualization software. When it comes to data, tableau helps to get the key insights out of it by creating appealing visualization in no time. Creating a visualization on the existing data from the business and doing descriptive analytics on top of it unfolds lot many hidden insights and help business and management to take quick and correct decisions.",
      p3: "Contact us now to know more about this best Tableau training course in Delhi NCR.",
    },
    Quick_Stats: {
      first:
        "Business intelligence and analytics industry is a volatile industry and picks up fast on technology. We have seen an upward trend for Tableau as a skill set.",
      second:
        "Tableau has a capability to connect with any databases and that gives an edge over excel and the appealing visualizations helps users to understand the details in quick time. Tableau with these capabilities become more and more in demand over other tools",
      third:
        "Facebook, General Motors, KPMG, Bank of America & other 30,000+ MNCs worldwide use Tableau across industries and also Tableau is a Leader in 2017 Gartner Magic Quadrant for Business Intelligence & Analytics Platforms.",
    },
    Instructors: [
      {
        name: "Baldev Bhatt",
        profile: "Data Scientist",
        intro:
          "Lokesh is a Senior SAS consultant and worked with prestigious companies like Genpact, Target, Barclays, MasterCard in the past 10 years and has been working in the Data industry since the beginning of his career. He has worked for international markets as an expert in data preparation and insights in the field of Analytics. He co-founded PSTAnalytics in 2010, and has taken up the initiative to spread quality education. He has worked extensively in domains such as Retail ,Pharma , BFSI ,Healthcare,Automotive, Hi-tech, and Telecom. His day to day task include campaign management, production, Data Warehousing, ETL, and process development for batter insights and rapid delivery with precision",
      },
    ],
    Benefits: {
      first: {
        title: "Tableau - Data Visualization",
        p: "Tableau is the major component of today's data science. To get the insights out of data and help in quick decisions. This is an added upskil in the skill list and will help you up the success ladder.",
      },
      second: {
        title: "Project Driven approach",
        p: "There will be no run through ppt and decks as we dont believe in such methods of learning and spreading education. It would be hands on and classes would actully be workshops with more focus on the application part.",
      },
      third: {
        title: "Tableau - Descriptive Statistics",
        p: "Higher Management are betting heavy on Tableau as a Descriptive analytics tool. The world is moving away from traditional BI tools to self service BI tools which is building a good place as far as business intelligence is concerned.",
      },
    },
    Who_Should_Attend: [
      "Final Year Students who are looking for Business Intelligence jobs",
      "Freshers out of the campus who are looking for jobs in start-ups",
      "Experienced folks who are in data industry and look forward to adapting in Business Intelligence",
      "Experienced professionals who look forward to switch the jobs/domain to Business Intelligence",
    ],
    Course_Outcome: [
      "Understand the outlining features of Tableau",
      "Strong foundation with more hold on basics",
      "Data Visualization and Analysis with Tableau",
      "In-depth knowlege of basic and advanced Tableau",
      "Proper application as to where and where not to apply",
    ],
    Curriculum: [
      {
        Step: "Basic Tableau",
        innerDetail: [
          "1. Introduction – Why data visualization",
          "2. Architecture of Tableau",
          "3. Welcome! What is Tableau & Course Overview?",
          "4. Installation",
        ],
      },
      {
        Step: "Data connectivity and navigation",
        innerDetail: [
          "1. Connecting Tableau with different data sources",
          "2. Working with data extracts",
          "3. Navigating tableau",
        ],
      },
      {
        Step: "My first visual - Bar chart",
        innerDetail: [
          "1. Business challenge",
          "2. Creating calculated field",
          "3. Adding colors",
          "4. Adding labels and formatting",
          "5. Exporting the worksheet",
        ],
      },
      {
        Step: "Time series, aggregation and filters",
        innerDetail: [
          "1. Working with time series",
          "2. Understanding Aggregation, Granularity and level of details",
          "3. Creating area chart and learning about highlighting",
          "4. Adding quick filters",
        ],
      },
      {
        Step: "Other charts in Tableau",
        innerDetail: [
          "1. Tree map",
          "2. Bubble chart",
          "3. Bullet chart",
          "4. Pie chart",
          "5. Other visuals",
        ],
      },
      {
        Step: "Maps and scatter plot",
        innerDetail: [
          "1. Creating maps and working with hierarchies",
          "2. Creating scatter plot and applying filters in multiple sheets",
          "3. Let's create the first dashboard",
          "4. Apply action filters",
        ],
      },
      {
        Step: "Joining and Data blending",
        innerDetail: [
          "1. Understand left, right, inner, outer joins",
          "2. Joins with duplicate values",
          "3. Join data vs Blending data in Tableau",
          "4. Editing blending relation",
        ],
      },
      {
        Step: "Advanced Tableau",
        innerDetail: [
          "1. Groups and Sets",
          "2. Working with Groups",
          "3. Creating dynamic sets",
          "4. Combining sets",
          "5. Controlling sets with parameter",
        ],
      },
      {
        Step: "Advanced Table calculation",
        innerDetail: [
          "1. Creating multiple joins",
          "2. Calculated field vs Table Calculation",
          "3. Creating advanced table calculation",
          "4. Saving quick table calculation",
          "5. Specifying direction of computation",
          "6. Writing your own table calculation",
          "7. Adding second layer of moving average",
          "8. Quality assurance of table calculation",
          "9. Trend lines for power insights",
        ],
      },
      {
        Step: "Advanced data preparation + Analytics in Tableau",
        innerDetail: [
          "1. Building box plots in Tableau",
          "2. Analysing box plots",
          "3. Working with large data sources",
          "4. Pivot and split",
          "5. Trend lines",
          "6. Data prep exercise",
          "7. Advanced time series blending",
          "8. Forecasting in Tableau",
          "9. Integration of Tableau with R",
        ],
      },
      {
        Step: "Creating animations in Tableau",
        innerDetail: ["1. Adding animation in the visualization"],
      },
      {
        Step: "Level of details (LOD)",
        innerDetail: [
          "1. LOD Type 1 (Include)",
          "2. Understanding ATTR in Tableau",
          "3. LOD Type 2 (Exclude)",
          "4. LOD Type 3 (Fixed)",
          "5. Multiple fields in LOD Calculations",
        ],
      },
      {
        Step: "Tableau Server",
        innerDetail: [
          "1. Users and Groups",
          "2. Publishing Dashboards to the server",
          "3. Difference between Tableau online and Tableau server",
        ],
      },
      {
        Step: "Basic SQL",
        innerDetail: [
          "1. Introduction to SQL and Relational Databases",
          "2. Basic SQL Syntax and Commands(SELECT, FROM, WHERE, etc.)",
          "3. Creating and Modifying Tables with DDL Statements",
          "4. DML statements(INSERT, UPDATE, DELETE)",
          "5. Filtering Data with WHERE Clause",
          "6. AND, IN, OR, NOT IN, BETWEEN, LIKE Operator",
          "7. Sorting Data with ORDER BY Clause",
          "8. Joining Tables with INNER JOIN, LEFT JOIN, RIGHT JOIN",
          "9. Union and Union All",
          "10. Grouping Data with GROUP BY Clause",
          "11. Aggregating Data with SQL Functions(COUNT, SUM, AVG, etc.)",
        ],
      },
      {
        Step: "Advanced SQL",
        innerDetail: [
          "1. Introduction to Window Functions",
          "2. Using Window Functions with OVER Clause",
          "3. Partitioning Data with PARTITION BY Clause",
          "4. Advanced Joins (OUTER JOIN, CROSS JOIN, SELF JOIN)",
          "5. Using Aliases to Simplify Queries",
          "6. Working with Date and Time Data",
          "7. Conditional Logic with CASE Statements",
          "8. Using Views",
          "9. Introduction to Stored Procedures",
          "10. Performance Tuning Techniques (query optimization)",
        ],
      },
    ],
    FAQs: [
      {
        Question: "What are the System Requirements?",
        Answer:
          "WindowsMicrosoft Windows 7 or newer (32-bit and 64-bit)Microsoft Server 2008 R2 or newerIntel Pentium 4 or AMD Opteron processor or newer2 GB memory1.5 GB minimum free disk space1366 by 768 screen resolution or higherAppleiMac/MacBook computers 2009 or newerOSX 10.10 or newer5 GB minimum free disk space1366 x 768 screen resolution or higher",
      },
      {
        Question: "How will be the Tableau training labs be conducted?",
        Answer:
          "Most of the labs and project during this Tableau training will be done using Tableau Public.",
      },
      {
        Question: "Who are the trainers for this Tableau training program?",
        Answer:
          "Highly qualified and certified instructors with industry relevant experience deliver proper Tableau training in Delhi, Noida and Gurgaon.",
      },
      {
        Question:
          "What are the modes of training offered for this course Tableau Training?",
        Answer:
          "Classroom training and instructor led online classroom Tableau training options are available. You have the option to attend the Tableau training course remotely from your desktop, laptop via video conferencing.",
      },
      {
        Question: "What if I miss a class?",
        Answer:
          "We provide the recordings of the class after the session is conducted. So, if you miss a class then you can go through the recordings before the next Tableau training class in Noida, Delhi and Gurgaon.",
      },
      {
        Question:
          "What is the difference between instructor led and self paced Tableau training program?",
        Answer:
          "In the self-paced training programme, learners have to learn about the Tableau training course on their own using the study materials provided to them after registration. There would be recorded videos which will be throughout this programme. In the Instructor-led version, learners will be provided assistance by faculty who will take them through the complete Tableau training course as a part of classroom/ live webinar mode",
      },
      {
        Question:
          "Can I attend a demo session of the Tableau training before enrollment?",
        Answer:
          "Yes, you can attend a demo session in any ongoing Tableau training batch in Gurgaon, Noida or Delhi.",
      },
    ],
  },

  Data_Visualization_PowerBi: {
    img: powerbi,
    navRoute: "power-bi",
    Title: "Data Visualization - PowerBi",
    Subtitle: "Data Visualization Course & Training",
    CourseFee: {
      Online: 15000,
      Instructor_Led: 15000,
    },
    Time: "40+",
    Students: "1000+",
    Instructors: [
      {
        name: "Baldev Bhatt",
        profile: "Data Scientist",
        intro:
          "Lokesh is a Senior SAS consultant and worked with prestigious companies like Genpact, Target, Barclays, MasterCard in the past 10 years and has been working in the Data industry since the beginning of his career. He has worked for international markets as an expert in data preparation and insights in the field of Analytics. He co-founded PSTAnalytics in 2010, and has taken up the initiative to spread quality education. He has worked extensively in domains such as Retail ,Pharma , BFSI ,Healthcare,Automotive, Hi-tech, and Telecom. His day to day task include campaign management, production, Data Warehousing, ETL, and process development for batter insights and rapid delivery with precision",
      },
    ],
    What_YouLL_Learn: {
      p1: "Learn Data Visualization concepts with Power BI hands-on at PST Analytics Classroom and Online Power BI Training And Certification Courses In Delhi, Gurgaon, Noida and other Indian cities.",
      p2: "Power BI is a very powerful and easy to understand data visualization software. When it comes to data, Power BI helps to get the key insights out of it by creating appealing visualization in no time. Creating a visualization on the existing data from the business and doing descriptive analytics on top of it unfolds lot many hidden insights and help business and management to take quick and correct decisions.",
      p3: "Contact us now to know more about this best Power BI training course in Delhi NCR.",
    },
    Quick_Stats: {
      first:
        "Business intelligence and analytics industry is a volatile industry and picks up fast on technology. We have seen an upward trend for Power BI as a skill set.",
      second:
        "Power BI has a capability to connect with any databases and that gives an edge over excel and the appealing visualizations helps users to understand the details in quick time. Power BI with these capabilities become more and more in demand over other tools",
      third:
        "Facebook, General Motors, KPMG, Bank of America & other 30,000+ MNCs worldwide use Power BI across industries and also Power BI is a Leader in 2017 Gartner Magic Quadrant for Business Intelligence & Analytics Platforms.",
    },
    Benefits: {
      first: {
        title: "Power BI - Data Visualization",
        p: "Power BI is the major component of today's data science. To get the insights out of data and help in quick decisions. This is an added upskil in the skill list and will help you up the success ladder.",
      },
      second: {
        title: "Project Driven approach",
        p: "There will be no run through ppt and decks as we dont believe in such methods of learning and spreading education. It would be hands on and classes would actully be workshops with more focus on the application part.",
      },
      third: {
        title: "Power BI - Descriptive Statistics",
        p: "Higher Management are betting heavy on Power BI as a Descriptive analytics tool. The world is moving away from traditional BI tools to self service BI tools which is building a good place as far as business intelligence is concerned.",
      },
    },
    Who_Should_Attend: [
      "Final Year Students who are looking for Business Intelligence jobs",
      "Freshers out of the campus who are looking for jobs in start-ups",
      "Experienced folks who are in data industry and look forward to adapting in Business Intelligence",
      "Experienced professionals who look forward to switch the jobs/domain to Business Intelligence",
    ],
    Course_Outcome: [
      "Understand the outlining features of Power BI",
      "Strong foundation with more hold on basics",
      "Data Visualization and Analysis with Power BI",
      "In-depth knowlege of basic and advanced Power BI",
      "Proper application as to where and where not to apply",
    ],
    Curriculum: [
      {
        Step: "Power BI Introduction",
        innerDetail: [
          "1. Data Visualization, Reporting",
          "2. Business Intelligence (BI), Traditional BI, Self-Serviced BI",
          "3. Cloud Based BI, On Premise BI",
          "4. Power BI Products",
          "5. Power BI Desktop (Power Query, Power Pivot, Power View)",
          "6. Flow of Work in Power BI Desktop",
          "7. Power BI Report Server, Power BI Service, Power BI Mobile Flow",
          "8. of Work in Power BI / Power BI Architecture",
          "9. A Brief History of Power BI",
        ],
      },
      {
        Step: "Power Query",
        innerDetail: [
          "1. Data Transformation, Benefits of Data Transformation",
          "2. Shape or Transform Data using Power Query",
          "3. Overview of Power Query / Query Editor, Query Editor User Interface The",
          "4. Ribbon (Home, Transform, Add Column, View Tabs)",
          "5. The Queries Pane, The Data View / Results Pane, The Query Settings Pane, Formula Bar",
          "6. Saving the Work",
          "7. Datatypes, Changing the Datatype of a Column Filter",
          "8. in Power Query",
          "9. Auto Filter / Basic Filtering",
          "10. Filter a Column using Text Filters",
          "11. Filter a Column using Number Filters",
        ],
      },
      {
        Step: "M Language",
        innerDetail: [
          "1. IF..ELSE Conditions, Transform Column () Types",
          "2. Remove Columns (), Split Columns (),Replace Value()",
          "3. Table. Distinct Options and GROUP BY Options Table.",
          "4. Group (), Table. Sort () with Type Conversions PIVOT",
          "5. Operation and Table. Pivot(). List Functions Using",
          "6. Parameters with M Language",
        ],
      },
      {
        Step: "Data Modeling",
        innerDetail: [
          "1. Data Modeling Introduction",
          "2. Relationship, Need of Relationship",
          "3. Relationship Types / Cardinality in General",
          "4. One-to-One, One-to-Many (or Many-to-One), Many-to-Many",
          "5. AutoDetect the relationship, Create a new relationship, Edit existing relationships",
          "6. Make Relationship Active or Inactive",
          "7. Delete a relationship",
        ],
      },
      {
        Step: "DAX",
        innerDetail: [
          "1. What is DAX, Calculated Column, Measures",
          "2. DAX Table and Column Name Syntax",
          "3. Creating Calculated Columns, Creating Measures Calculated",
          "4. Columns Vs Measures",
          "5. DAX Syntax & Operators",
          "6. DAX Operators",
          "7. Types of Operators",
          "8. Arithmetic Operators, Comparison Operators, Text Concatenation Operator, Logical",
          "9. Operators",
        ],
      },
      {
        Step: "DAX Functions Types",
        innerDetail: [
          "1. Date and Time Functions",
          "2. Text Functions",
          "3. Logical Functions",
          "4. Math & Statistical Functions",
          "5. Filter Functions",
        ],
      },
      {
        Step: "Time Intelligence Functions Date and Time Functions",
        innerDetail: [
          "1. YEAR, MONTH, DAY",
          "2. WEEKDAY, WEEKNUM",
          "3. FORMAT (Text Function) à Month Name, Weekday Name",
          "4. DATE, TODAY, NOW",
          "5. HOUR, MINUTE, SECOND, TIME",
          "6. DATEDIFF, CALENDAR",
          "7. Creating Date Dimension Table",
        ],
      },
      {
        Step: "Text Functions",
        innerDetail: [
          "1. LEN, CONCATENATE (&)",
          "2. LEFT, RIGHT, MID UPPER,",
          "3. LOWER",
          "4. TRIM, SUBSTITUTE, BLANK",
        ],
      },
      {
        Step: "Logical Functions",
        innerDetail: [
          "1. IF",
          "2. TRUE, FALSE NOT,",
          "3. OR, IN, AND",
          "4. IFERROR SWITCH",
        ],
      },
      {
        Step: "Math & Statistical Functions",
        innerDetail: [
          "1. INT",
          "2. ROUND, ROUNDUP, ROUNDDOWN",
          "3. DIVIDE",
          "4. EVEN, ODD",
          "5. POWER, SIGN",
          "6. SQRT, FACT",
          "7. SUM, SUMX",
          "8. MIN, MINX",
          "9. MAX, MAXX",
          "10. COUNT, COUNTX",
          "11. AVERAGE, AVERAGEX",
        ],
      },
      {
        Step: "Filter Functions",
        innerDetail: ["1. CALCULATE", "2. ALL", "3. RELATED", "4. Report View"],
      },
      {
        Step: "Report View User Interface",
        innerDetail: [
          "1. Fields Pane, Visualizations pane, Ribbon, Views, Pages Tab, Canvas Visual",
          "2. Interactions",
          "3. Interaction Type (Filter, Highlight, None)",
          "4. Visual Interactions Default Behavior, Changing the Interaction",
        ],
      },
      {
        Step: "Grouping and Binning Introduction",
        innerDetail: [
          "1. Using grouping, Creating Groups on Text Columns",
          "2. Using binning, Creating Bins on Number Column and Date Columns",
          "3. Sorting Data in Visuals",
          "4. Changing the Sort Column, Changing the Sort Order",
          "5. Sort using column that is not used in the Visualization",
          "6. Sort using the Sort by Column button",
          "7. Hierarchy Introduction, Default Date Hierarchy",
          "8. Creating Hierarchy, Creating Custom Date Hierarchy",
          "9. Change Hierarchy Levels",
          "10. Drill-Up and Drill-Down Reports",
          "11. Data Actions, Drill Down, Drill Up, Show Next Level",
        ],
      },
      {
        Step: "Visualizations",
        innerDetail: [
          "1. Visualizing Data, Why Visualizations",
          "2. Visualization types, Create and Format Bar and Column Charts",
          "3. Create and Format Stacked Bar Chart Stacked Column Chart Create",
          "4. and Format Clustered Bar Chart, Clustered Column Chart",
          "5. Create and Format 100% Stacked Bar Chart, 100% Stacked Column Chart Create and",
          "6. Format Pie and Donut Charts",
          "7. Create and Format Scatter Charts",
          "8. Create and Format Table Visual, Matrix Visualization",
          "9. Line and Area Charts",
          "10. Create and Format Line Chart, Area Chart, Stacked Area Chart",
          "11. Combo Charts",
        ],
      },
      {
        Step: "Power BI Service",
        innerDetail: [
          "1. Power BI Service Introduction, Power BI Cloud Architecture",
          "2. Creating Power BI Service Account, SIGN IN to Power BI Service Account",
          "3. Publishing Reports to the Power BI service, Import / Getting the Report to PBI Service My",
          "4. Workspace / App Workspaces Tabs",
          "5. DATASETS, WORKBOOKS, REPORTS, DASHBOARDS",
          "6. Working with Datasets, Creating Reports in Cloud using Published Datasets",
        ],
      },
      {
        Step: "Creating Dashboards",
        innerDetail: [
          "1. Pin Visuals and Pin LIVE Report Pages to Dashboard",
          "2. Advantages of Dashboards",
          "3. Interacting with Dashboards",
          "4. Formatting Dashboard",
          "5. Sharing Dashboard",
        ],
      },
      {
        Step: "Basic SQL",
        innerDetail: [
          "1. Introduction to SQL and Relational Databases",
          "2. Basic SQL Syntax and Commands(SELECT, FROM, WHERE, etc.)",
          "3. Creating and Modifying Tables with DDL Statements",
          "4. DML statements(INSERT, UPDATE, DELETE)",
          "5. Filtering Data with WHERE Clause",
          "6. AND, IN, OR, NOT IN, BETWEEN, LIKE Operator",
          "7. Sorting Data with ORDER BY Clause",
          "8. Joining Tables with INNER JOIN, LEFT JOIN, RIGHT JOIN",
          "9. Union and Union All",
          "10. Grouping Data with GROUP BY Clause",
          "11. Aggregating Data with SQL Functions(COUNT, SUM, AVG, etc.)",
        ],
      },
      {
        Step: "Advanced SQL",
        innerDetail: [
          "1. Introduction to Window Functions",
          "2. Using Window Functions with OVER Clause",
          "3. Partitioning Data with PARTITION BY Clause",
          "4. Advanced Joins (OUTER JOIN, CROSS JOIN, SELF JOIN)",
          "5. Using Aliases to Simplify Queries",
          "6. Working with Date and Time Data",
          "7. Conditional Logic with CASE Statements",
          "8. Using Views",
          "9. Introduction to Stored Procedures",
          "10. Performance Tuning Techniques (query optimization)",
        ],
      },
      {
        Step: "Project",
        innerDetail: ["1. Real Time Project"],
      },
    ],
    FAQs: [
      {
        Question: "What are the System Requirements?",
        Answer:
          "WindowsMicrosoft Windows 7 or newer (32-bit and 64-bit)Microsoft Server 2008 R2 or newerIntel Pentium 4 or AMD Opteron processor or newer2 GB memory1.5 GB minimum free disk space1366 by 768 screen resolution or higherAppleiMac/MacBook computers 2009 or newerOSX 10.10 or newer5 GB minimum free disk space1366 x 768 screen resolution or higher",
      },
      {
        Question: "How will be the Tableau training labs be conducted?",
        Answer:
          "Most of the labs and project during this Tableau training will be done using Tableau Public.",
      },
      {
        Question: "Who are the trainers for this Tableau training program?",
        Answer:
          "Highly qualified and certified instructors with industry relevant experience deliver proper Tableau training in Delhi, Noida and Gurgaon.",
      },
      {
        Question:
          "What are the modes of training offered for this course Tableau Training?",
        Answer:
          "Classroom training and instructor led online classroom Tableau training options are available. You have the option to attend the Tableau training course remotely from your desktop, laptop via video conferencing.",
      },
      {
        Question: "What if I miss a class?",
        Answer:
          "We provide the recordings of the class after the session is conducted. So, if you miss a class then you can go through the recordings before the next Tableau training class in Noida, Delhi and Gurgaon.",
      },
      {
        Question:
          "What is the difference between instructor led and self paced Tableau training program?",
        Answer:
          "In the self-paced training programme, learners have to learn about the Tableau training course on their own using the study materials provided to them after registration. There would be recorded videos which will be throughout this programme. In the Instructor-led version, learners will be provided assistance by faculty who will take them through the complete Tableau training course as a part of classroom/ live webinar mode",
      },
      {
        Question:
          "Can I attend a demo session of the Tableau training before enrollment?",
        Answer:
          "Yes, you can attend a demo session in any ongoing Tableau training batch in Gurgaon, Noida or Delhi.",
      },
    ],
  },
};
