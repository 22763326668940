import React, { useEffect, useState } from "react";
import "../CSS/ContactUsPage.scss";
// import ContactForm from './ContactForm';
import contactImg from "../Media/contact.jpg";
import ContactUsForm from "./ContactUsForm";
import { Helmet } from "react-helmet";

export default function ContactUsPage() {
  const [scroll, setScroll] = useState(false);
  const heightFromTop = 25;

  useEffect(() => {
    const scrollFunc = () => {
      const scrolled = window.scrollY >= heightFromTop;
      setScroll(scrolled);
    };

    window.addEventListener("scroll", scrollFunc);

    return () => {
      window.removeEventListener("scroll", scrollFunc);
    };
  }, [heightFromTop]);
  return (
 
    <>
      <Helmet>
        <title>Contact US | PST Analytics</title>
      </Helmet>
      <div className="container-fluid ContactUs_Container">
        <div
          className={scroll ? "row rowScrolledDiv" : "row rowNormalDiv"}
          style={
            scroll
              ? { background: "none" }
              : { background: `url(${contactImg})` }
          }
        >
          <div className="col-12">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>
      <div className={scroll ? "rowScrolledform" : "rowNormalform"}>
        <ContactUsForm />
      </div>
    </>
  );
}
