import React, { useEffect, useState } from "react";
import university from "./Media/university.jpg";
import { Helmet } from "react-helmet";

export default function Corporate() {
  const [scroll, setScroll] = useState(false);
  const heightFromTop = 25;

  useEffect(() => {
    const scrollFunc = () => {
      const scrolled = window.scrollY >= heightFromTop;
      setScroll(scrolled);
    };

    window.addEventListener("scroll", scrollFunc);

    return () => {
      window.removeEventListener("scroll", scrollFunc);
    };
  }, [heightFromTop]);

  return (
    <>
      <Helmet>
        <title>Corporate Training | PST Analytics</title>
        <meta
          name="description"
          content="We at PST Analytics provide training to the corporate world in Analytics and Big Data. We conduct both onsite and virtual training according to the preferences. For further info call us 9811552060."
        />
      </Helmet>
      <div className="container-fluid universityTraining_Container">
        <div
          className={scroll ? "row rowScrolledDiv" : "row rowNormalDiv"}
          style={
            scroll
              ? { background: "none" }
              : { background: `url(${university})` }
          }
        >
          <div className="col-12">
            <h1>Corporate Training</h1>
          </div>
        </div>
      </div>
      <div className={scroll ? "rowScrolledform" : "rowNormalform"}>
        <div className="container-fluid">
          <div className="container">
            <h1 className="h1">Trusted by big brands</h1>
            <p>
              Leading names in the corporate world rely on us for their
              analytics and big data training needs. We conduct training onsite
              or through virtual classrooms, depending on your choice.
            </p>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <h1 className="h1">Features</h1>
            <p>
              Along with training, we offer a wide variety of features to
              enhance employee experience:
            </p>
            <div className="row d-flex">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h1 className="h1">SAS Tutors</h1>
                <p>
                  We are professionals working in IT industries with top MNCs.
                  Our experts know everything about Base and Advance SAS,
                  Business Analytics Training and certification.
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h1 className="h1">Faculty Support</h1>
                <p>
                  PST Analytics provide 100% faculty support during the courses
                  and also after course completion. We are always there to
                  provide you the required assistance.
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h1 className="h1">Certification</h1>
                <p>
                  We have a proven track record of students clearing the
                  certifications with highest grades.
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h1 className="h1">Learning Center</h1>
                <p>
                  PST Analytics provide interactive live session with tutors.
                  Our learning centers provide high technological hands-on
                  training to all students.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <h1 className="h1">Benefits</h1>
            <h6 className="h6">IT professionals</h6>
            <h6 className="h6">
              IT professionals looking for a change to cloud technologies
            </h6>
            <h6 className="h6">
              Fresher looking for opportunities in cloud technologies
            </h6>
            <h6 className="h6">Software Professionals</h6>
            <h6 className="h6">Analytics Professionals</h6>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <h1 className="h1">Our Methodologies</h1>
            <div className="row d-flex">
              <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <h1 className="h2">Self-paced Learning</h1>
                <p>
                  Self-paced learning allows you to proceed from one topic to
                  the next at your own speed. Besides you have the industry's
                  best online content available which is accessible anywhere.
                </p>
              </div>
              <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <h1 className="h2">Online Instructor-Led</h1>
                <p>
                  We provide you the opportunity to communicate with faculty,
                  who are experts in their respective fields, so that you can
                  make the most of your training.
                </p>
              </div>
              <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <h1 className="h2">On-Site Training</h1>
                <p>
                  You can have our instructors come and train you at your own
                  campus as per your convenient time slots. We ensure your time
                  is used productively.
                </p>
              </div>
              <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <h1 className="h2">Blended Training</h1>
                <p>
                  Our unique blended training lets you get the best of both
                  Self-Paced and On-Site Training.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
