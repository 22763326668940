import React from "react";
import "./SCSS/Outcome.scss";

export default function Outcome({ Outcome }) {
  return (
    <>
      <div className="Outcome">
        {Outcome.length > 0 &&
          Outcome.map((item, index) => (
            <div key={index}>
              <i className="bi bi-caret-right"></i>
              <p>{item}</p>
            </div>
          ))}
      </div>
    </>
  );
}
