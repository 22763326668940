import React from "react";
import "./SCSS/Benefits.scss";

export default function Benefits({ Benefits }) {
  return (
    <>
      <div className="Benefits">
        {Benefits.hasOwnProperty("first") && (
          <div>
            <div className="icon_">
              <i className="bi bi-hand-thumbs-up"></i>
            </div>
            <div className="text_">
              <h6>{Benefits.first.title}</h6>
              <p>{Benefits.first.p}</p>
            </div>
          </div>
        )}

        {Benefits.hasOwnProperty("second") && (
          <div>
            <div className="icon_">
              <i className="bi bi-bar-chart"></i>
            </div>
            <div className="text_">
              <h6>{Benefits.second.title}</h6>
              <p>{Benefits.second.p}</p>
            </div>
          </div>
        )}

        {Benefits.hasOwnProperty("third") && (
          <div>
            <div className="icon_">
              <i className="bi bi-display"></i>
            </div>
            <div className="text_">
              <h6>{Benefits.third.title}</h6>
              <p>{Benefits.third.p}</p>
            </div>
          </div>
        )}

        {Benefits.hasOwnProperty("fourth") && (
          <div>
            <div className="icon_">
              <i className="bi bi-award"></i>
            </div>
            <div className="text_">
              <h6>{Benefits.fourth.title}</h6>
              <p>{Benefits.fourth.p}</p>
            </div>
          </div>
        )}

        {Benefits.hasOwnProperty("fifth") && (
          <div>
            <div className="icon_">
              <i className="bi bi-person-check-fill"></i>
            </div>

            <div className="text_">
              <h6>{Benefits.fifth.title}</h6>
              <p>{Benefits.fifth.p}</p>
            </div>
          </div>
        )}
        {Benefits.hasOwnProperty("sixth") && (
          <div>
            <div className="icon_">
              <i className="bi bi-award"></i>
            </div>
            <div className="text_">
              <h6>{Benefits.sixth.title}</h6>
              <p>{Benefits.sixth.p}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
