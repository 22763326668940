import React from 'react';
// import img from '../Media/DataScience.jpg';
import '../CSS/CourseCard.scss';
import { Link } from 'react-router-dom';

export default function CourseCard({CourseData}) {
    return (
        <>
            <div className="card CourseCard col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4" style={{width: '30%'}}>
                <img src={CourseData.img} className="card-img-top" alt="" />
                    <div className="card-body text-center">
                        <h5 className="card-title">{CourseData.Title}</h5>
                        <p className="card-text">{CourseData.Subtitle}</p>
                        <div className='stars'>
                        <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i>
                        <i className="bi bi-star-fill text-warning"></i>
                        </div>
                        <div className='prices'>
                            <h6>Instructor led: <span style={{textDecorationLine:'line-through'}}>{CourseData.CourseFee.Instructor_Led + 10990}</span> {CourseData.CourseFee.Instructor_Led}</h6>
                            <h6>Online:  <span style={{textDecorationLine:'line-through'}}>{CourseData.CourseFee.Online + 10990}</span> {CourseData.CourseFee.Online}</h6>
                        </div>
                        <div className="students">
                            <h4>Students:{CourseData.Students}</h4>
                        </div>
                        <Link to={`/${CourseData.navRoute}`} className="btn btn-outline-primary">Check Now</Link>
                    </div>
            </div>
        </>
    )
}
