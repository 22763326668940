import React, { useEffect, useState } from "react";
import "../CSS/HireFromUs.scss";
import hireImg from "../Media/hire.jpg";
import HireFromUsForm from "./HireFromUsForm";
import { Helmet } from "react-helmet";

export default function HireFromUs() {
  const [scroll, setScroll] = useState(false);
  const heightFromTop = 25;

  useEffect(() => {
    const scrollFunc = () => {
      const scrolled = window.scrollY >= heightFromTop;
      setScroll(scrolled);
    };

    window.addEventListener("scroll", scrollFunc);

    return () => {
      window.removeEventListener("scroll", scrollFunc);
    };
  }, [heightFromTop]);

  return (
    <>
      <Helmet>
        <title>Hire From Us | PST Analytics</title>
      </Helmet>
      <div className="container-fluid HireFromUs_Container">
        <div
          className={scroll ? "row rowScrolledDiv" : "row rowNormalDiv"}
          style={
            scroll ? { background: "none" } : { background: `url(${hireImg})` }
          }
        >
          <div className="col-12">
            <h1>Hire From Us</h1>
            <p>
              Consider our students, They are best to fit to your requirements
            </p>
          </div>
        </div>
        <div className={scroll ? "rowScrolledDatadiv" : "rowNormalDatadiv"}>
          <div className="container">
            <h1>Hire the best Talent in the market</h1>

            <div className="descrip">
              <div>
                <div className="icon_">
                  <i className="bi bi-hand-thumbs-up"></i>
                </div>
                <div className="text_">
                  <h6>Why our students?</h6>
                  <p>
                    Over the last three year our centre in Delhi has helped more
                    than 500 students to improve their SAS skills.As a result,
                    these students have earned better knowledge, and grown in
                    self-esteem and confidence.And our students are placed in
                    some of the fortune 500 companies like Novarties and more.
                  </p>
                </div>
              </div>

              <div>
                <div className="icon_">
                  <i className="bi bi-bar-chart"></i>
                </div>
                <div className="text_">
                  <h6>The value we add!</h6>
                  <p>
                    Over the last three year our centre in Delhi has helped more
                    than 500 students to improve their SAS skills.As a
                    result,these students have earned better knowledge, and
                    grown in self-esteem and confidence. And our students are
                    placed in some of the fortune 500 companies like Novarties
                    and more.
                  </p>
                </div>
              </div>

              <div>
                <div className="icon_">
                  <i className="bi bi-display"></i>
                </div>
                <div className="text_">
                  <h6>The value we add!</h6>
                  <p>
                    Over the last three year our centre in Delhi has helped more
                    than 500 students to improve their SAS skills.As a
                    result,these students have earned better knowledge, and
                    grown in self-esteem and confidence. And our students are
                    placed in some of the fortune 500 companies like Novarties
                    and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <HireFromUsForm />
    </>
  );
}
