import React from "react";
import "./SCSS/Instructor.scss";
import img from "../ModulesImages/person.jpg";

export default function Instructor({ Instructors }) {
  return (
    <>
      <div className="Instructor">
        {Instructors.length > 0 &&
          Instructors.map((item, index) => (
            <div key={index}>
              <div className="img">
                <img src={img} alt="" />
              </div>
              <div className="about">
                <h2>{item.name}</h2>
                <h3>{item.profile}</h3>
                <p>{item.intro}</p>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
