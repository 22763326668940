import React from "react";
import "../CSS/Home.scss";
import {
  CourseData,
  Our_Benefits,
  Our_Acheivements,
} from "../Constants/CourseData";
import bg_Vid from "../Media/home1.mp4";
import CourseCard from "./CourseCard";
import IIofAnalyticsCard from "./IIofAnalyticsCard";
import Achievement from "./Achievement";
import OurBenefits from "./OurBenefits";
import globalcertification from "../Media/IIA-GlobalCertification.webp";
import industryrelevant from "../Media/IIA-industryRelevant.webp";
import mockexam from "../Media/IIA-MockExam.webp";
import airtel from "../Media/topBrands/airtel.webp";
import aithent from "../Media/topBrands/aithent.webp";
import amazon from "../Media/topBrands/amazon.webp";
import aonhewitt from "../Media/topBrands/aonhewitt.webp";
import hp from "../Media/topBrands/hp.webp";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>
          'PST Analytics | Data Science Training and Machine Learning Courses in
          Delhi NCR and Gurgaon
        </title>
        <meta
          name="description"
          content="PST Analytics offers Data Science training and Machine Learning Course in Delhi, Gurgaon and NCR. We provide classroom data analytics training with global certification and certification courses."
        />
        <meta
          name="keywords"
          content="data science course in delhi, data science course in delhi ncr, data science course in gurgaon, data science training in delhi, data science training in gurgaon, data science training in new delhi, data analytics courses in delhi, data analytics training in delhi, machine learning course in delhi, machine learning training in delhi"
        />
      </Helmet>
      <div style={{ overflowX: "hidden" }} className="HomePage">
        <video autoPlay loop muted>
          <source src={bg_Vid} type="video/mp4" />
        </video>

        <div className="Home-text container-fluid">
          <h1>
            <span>Data</span> WorkShops
          </h1>
          <h3>Transforming careers with Authenticity...</h3>
        </div>
      </div>

      {/* ------------------------------------------------------ */}
      <div className="container-fluid courses_Container">
        <div className="container">
          <div className="row d-flex gap-5 gap-lg-4">
            <CourseCard CourseData={CourseData.Industry360} />
            <CourseCard CourseData={CourseData.DataAnalytics_usingSAS} />
            <CourseCard
              CourseData={CourseData.Machine_Learning_And_AI_Using_Python}
            />
            <CourseCard CourseData={CourseData.Python} />
            <CourseCard CourseData={CourseData.Data_Visualization_Tableu} />
            <CourseCard CourseData={CourseData.Data_Visualization_PowerBi} />
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------ */}
      <div className="container-fluid InteractiveInstitute_OfAnalytics">
        <h1>Interactive </h1>
        <div className="heading_div">
          <h1>Institute of Analytics</h1>
        </div>
        <div className="para_div">
          <p>
            We are experienced professionals working in the data & consulting
            industry who have passionately trained thousands of students
            globally. Here are three reasons why you should choose us.
          </p>
        </div>
        <div className="container mt-5">
          <div className="row d-flex">
            <IIofAnalyticsCard
              img={industryrelevant}
              p={
                "Industry Relevant Online Analytics Training Certification With Centers In Delhi & Gurgaon"
              }
            />
            <IIofAnalyticsCard
              p={"Global Certification guarantee"}
              img={globalcertification}
            />
            <IIofAnalyticsCard p={"Mock Exam Preparation"} img={mockexam} />
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------ */}
      <div
        className="container-fluid py-5 my-5"
        style={{ background: "rgba(77, 77, 77, 0.223)" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center">
            <h2 className="h2 text-center">
              PST Analytics: Global Analytics Certification Online
            </h2>
            <h5 className="h5 text-center" style={{ fontWeight: "200" }}>
              PST Analytics is an instructor-led live online Analytics training
              certification institute based in Delhi and Gurgaon, which offers
              professional Analytics certification courses to beginners,
              advanced programmers and experts, who want to improve their
              knowledge of Applied analytics certification. We are one of the
              largest online enterprises dedicated to offering the best global
              analytics online training certification across the globe and in
              the web space.
            </h5>
            <br />
            <br />
            <br />
            <br />

            <h5 className="h5 text-center" style={{ fontWeight: "200" }}>
              Our data-driven certification courses welcome programmers from
              every part of the globe and offer in-depth studying programs for
              any level of difficulty. Our qualified trainers have all the
              necessary skills and knowledge to teach you everything you need to
              know in order to work in the data industry with certification,
              from basics to the complicated and rewarding online analytics
              training with classroom training in Delhi, Gurgaon & Noida. We
              also offer courses on the domain with language building blocks as
              Python, R, SAS, Excel, SQL Tableau and Hadoop.
            </h5>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------ */}
      <div className="container Benefits_Container">
        <div className="Benefits_Heading_Div">
          <h1>
            Our <span>Benefits</span>
          </h1>
        </div>
        <div className="row">
          {Our_Benefits.length > 0 &&
            Our_Benefits.map((item, index) => (
              <OurBenefits key={index} Our_Benefits={item} />
            ))}
        </div>
      </div>
      {/* ------------------------------------------------------ */}

      <div className="container-fluid py-5" style={{ background: "#32c1ed2c" }}>
        <div className="container Achievements">
          <div className="achievement_div"></div>
          <h1 className="achievement_h1">
            Our{" "}
            <span>
              Achievements <i className="bi bi-trophy"></i>
            </span>
          </h1>
          <p>
            Unbeatable <span>Track Record</span>. We let the data speak!
          </p>
          <div className="row">
            {Our_Acheivements.length > 0 &&
              Our_Acheivements.map((item, index) => (
                <Achievement Our_Acheivements={item} key={index} />
              ))}
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------*/}
      <div
        className="container-fluid top-brands py-3 py-sm-3 py-md-4 py-lg-3 py-xl-4"
        style={{ background: "#0000002d" }}
      >
        <h3 className="h3 text-dark text-center fw-light">
          Leap forward with the biggest Corporations in the World
        </h3>
        <div className="container  py-3 py-sm-3 py-md-4 py-lg-5 py-xl-5">
          <div className="row d-flex">
            <div style={{ width: "20%" }}>
              <img src={airtel} alt="airtel" />
            </div>
            <div style={{ width: "20%" }}>
              <img src={aonhewitt} alt="aonhewitt" />
            </div>
            <div style={{ width: "20%" }}>
              <img src={aithent} alt="aithent" />
            </div>
            <div style={{ width: "20%" }}>
              <img src={hp} alt="hp" />
            </div>
            <div style={{ width: "20%" }}>
              <img src={amazon} alt="amazon" />
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------------------------------- */}
      <div className="container-fluid pt-5 mt-5">
        <div className="container mt-5">
          <div className="row justify-content-center">
            <h2 className="h2 text-center">Need help to your requirement?</h2>
            <h4 className="h4 text-center" style={{ fontWeight: "200" }}>
              +918377953224
            </h4>
            <h4 className="h4 text-center" style={{ fontWeight: "200" }}>
              info@pstanalytics.com
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}
