import React, { useState } from "react";
import "./Module CSS/Interested.scss";
import Loading from "../Loading";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../Service/Firebase.config";

export default function Interested() {
  let newDate = new Date();
  let date = newDate.toDateString();

  const formData = {
    name: "",
    email: "",
    phone: "",
    message: "",
    date: date,
    status: "not done",
  };

  const [inputFormData, setInputFormData] = useState(formData);
  const { name, email, phone, message } = inputFormData;
  const [showLoading, setShowLoading] = useState(false);

  const inputChange = (e) => {
    setInputFormData({
      ...inputFormData,
      [e.target.name]: e.target.value,
    });
  };

  // errors
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  // submitted
  const [submitted, setsubmitted] = useState(false);

  const submit = async (e) => {
    e.preventDefault();

    if (name.length > 0) {
      if (
        phone.length > 0 &&
        phone.length === 10 &&
        /^\d*\.?\d*$/.test(phone)
      ) {
        if (email.length > 0 && email.includes("@") && email.includes(".")) {
          if (message.length > 0) {
            try {
              setShowLoading(true);
              await addDoc(collection(db, "CourseInquiry"), inputFormData);
              // const res = await axios.post(
              //   "/pstAPI/v1/sendMailAndSubmitData",
              //   inputFormData
              // );
              // if (res.data.hasOwnProperty("Success")) {
              //   if (res.data.Success) {
              setInputFormData(formData);
              setsubmitted(true);
              //     setShowLoading(false);
              //   } else {
              //     setShowLoading(false);
              //     throw new Error(
              //       "Something went wrong! Please try to submit again."
              //     );
              //   }
              // } else {
              //   setShowLoading(false);
              //   throw new Error(
              //     "Something went wrong! Please try to submit again."
              //   );
              // }
              setShowLoading(false);
            } catch (error) {
              setShowLoading(false);
              alert(error.message.toUpperCase());
            }
          } else {
            setMessageError(true);
          }
        } else {
          setEmailError(true);
        }
      } else {
        setPhoneError(true);
      }
    } else {
      setNameError(true);
    }
  };
  // temporary------------------------

  return (
    <>
      <div className="interested_btn_div">
        <button
          type="button"
          className="interested btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Interested
        </button>
      </div>

      <div
        className="modal FormModalInterested fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog container">
          <form className="modal-content" onSubmit={submit}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Interested In this Course
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setsubmitted(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                <input
                  onInput={inputChange}
                  onChange={() => {
                    setNameError(false);
                  }}
                  type="text"
                  name="name"
                  value={name}
                  className="form-control"
                  placeholder="Full Name"
                />
                {nameError && (
                  <p className="text-danger">Please enter your name.</p>
                )}
              </div>
              <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                <input
                  onInput={inputChange}
                  onChange={() => {
                    setPhoneError(false);
                  }}
                  type="tel"
                  value={phone}
                  name="phone"
                  className="form-control"
                  placeholder="Phone Number"
                />
                {phoneError && (
                  <p className="text-danger">
                    Please enter a valid Phone number.
                  </p>
                )}
              </div>
              <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                <input
                  onInput={inputChange}
                  onChange={() => {
                    setEmailError(false);
                  }}
                  type="email"
                  value={email}
                  name="email"
                  className="form-control"
                  placeholder="Email"
                />
                {emailError && (
                  <p className="text-danger">Please enter a valid email.</p>
                )}
              </div>
              <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 ">
                <textarea
                  onInput={inputChange}
                  onChange={() => {
                    setMessageError(false);
                  }}
                  className="form-control"
                  placeholder="Message"
                  name="message"
                  value={message}
                  rows="5"
                ></textarea>
                {messageError && (
                  <p className="text-danger">Please enter a Message for us.</p>
                )}
                {submitted && <p className="text-success">Submitted</p>}
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-outline-primary">
                Request a call back
              </button>
            </div>
          </form>
        </div>
        {showLoading && <Loading contactPage={false} />}
      </div>
    </>
  );
}
