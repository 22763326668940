import React from "react";
import "../CSS/OurBenefits.scss";

export default function OurBenefits({ Our_Benefits }) {
  return (
    <>
      <div className="card OurBenefits col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
        <div className="card-body">
          <h5 className="card-title">{Our_Benefits.title}</h5>
          <h6 className="see_how">See how-</h6>
          <p className="card-text">{Our_Benefits.p}</p>
        </div>
      </div>
    </>
  );
}
