import React, { useState } from "react";
import Loading from "./Loading";
import "../CSS/ContactForm.scss";
import { addDoc, collection } from "firebase/firestore";
import { db } from "./Service/Firebase.config";

export default function ContactUsForm() {
  let newDate = new Date();
  let date = newDate.toDateString();

  const formData = {
    name: "",
    email: "",
    phone: "",
    message: "",
    date: date,
    status: "not done",
  };

  // temporary------------------------
  const [inputFormData, setInputFormData] = useState(formData);

  const { name, email, phone, message } = inputFormData;
  const [showLoading, setShowLoading] = useState(false);

  const inputChange = (e) => {
    setInputFormData({
      ...inputFormData,
      [e.target.name]: e.target.value,
    });
  };

  // errors
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  // submitted
  const [submitted, setsubmitted] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    if (name.length > 0) {
      if (
        phone.length > 0 &&
        phone.length === 10 &&
        /^\d*\.?\d*$/.test(phone)
      ) {
        if (email.length > 0 && email.includes("@") && email.includes(".")) {
          if (message.length > 0) {
            try {
              setShowLoading(true);
              await addDoc(collection(db, "ContactUs"), inputFormData);

              // const res = await axios.post(
              //   "/pstAPI/v1/sendMailAndSubmitData",
              //   inputFormData
              // );
              // if (res.data.hasOwnProperty("Success")) {
              //   if (res.data.Success) {
              setInputFormData(formData);
              setsubmitted(true);
              //     setShowLoading(false);
              //   } else {
              //     setShowLoading(false);
              //     throw new Error(
              //       "Something went wrong! Please try to submit again."
              //     );
              //   }
              // } else {
              //   setShowLoading(false);
              //   throw new Error(
              //     "Something went wrong! Please try to submit again."
              //   );
              // }

              setShowLoading(false);
            } catch (error) {
              setShowLoading(false);
              alert(error.message.toUpperCase());
            }
          } else {
            setMessageError(true);
          }
        } else {
          setEmailError(true);
        }
      } else {
        setPhoneError(true);
      }
    } else {
      setNameError(true);
    }
  };
  // temporary------------------------

  return (
    <>
      <form className="container ContactForm" onSubmit={submit}>
        <div className="header">
          <h5 className="title">
            Please fill this small form & we shall get back
          </h5>
        </div>
        <div className="body">
          <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
            <input
              onInput={inputChange}
              onChange={() => {
                setNameError(false);
              }}
              type="text"
              name="name"
              value={name}
              className="form-control"
              placeholder="Full Name"
            />
            {nameError && (
              <p className="text-danger">Please enter your name.</p>
            )}
          </div>
          <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
            <input
              onInput={inputChange}
              onChange={() => {
                setPhoneError(false);
              }}
              type="tel"
              value={phone}
              name="phone"
              className="form-control"
              placeholder="Phone Number"
            />
            {phoneError && (
              <p className="text-danger">Please enter a valid Phone number.</p>
            )}
          </div>
          <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
            <input
              onInput={inputChange}
              onChange={() => {
                setEmailError(false);
              }}
              type="email"
              value={email}
              name="email"
              className="form-control"
              placeholder="Email"
            />
            {emailError && (
              <p className="text-danger">Please enter a valid email.</p>
            )}
          </div>
          <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 ">
            <textarea
              onInput={inputChange}
              onChange={() => {
                setMessageError(false);
              }}
              className="form-control"
              placeholder="Message"
              name="message"
              value={message}
              rows="5"
            ></textarea>
            {messageError && (
              <p className="text-danger">Please enter a Message for us.</p>
            )}
            {submitted && <p className="text-success">Submitted</p>}
          </div>
        </div>
        <div className="footer">
          <button type="submit" className="btn btn-outline-primary">
            Request a call back
          </button>
        </div>
      </form>

      <div
        className="container-fluid mt-5 pt-5"
        style={{ background: "#0a6b8927" }}
      >
        <div className="container">
          <h2
            className="h2 text-center"
            style={{ fontWeight: "lighter", color: "#0A6C89" }}
          >
            And this is not all! Please contact us for more offers.
          </h2>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-center py-5">
              <h5
                className="h5"
                style={{ fontWeight: "200", color: "#0A6C89" }}
              >
                Contact Phone Number
              </h5>
              <h5 className="h5" style={{ fontWeight: "200" }}>
                +918377953224
              </h5>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-center py-5">
              <h5
                className="h5"
                style={{ fontWeight: "200", color: "#0A6C89" }}
              >
                Our Email Address
              </h5>
              <h5 className="h5" style={{ fontWeight: "200" }}>
                info@pstanalytics.com
              </h5>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 text-center py-5">
              <h5
                className="h5"
                style={{ fontWeight: "200", color: "#0A6C89" }}
              >
                Our Location
              </h5>
              <h5 className="h5" style={{ fontWeight: "200" }}>
                Operating Online
              </h5>
            </div>
          </div>
        </div>
      </div>
      {showLoading && <Loading contactPage={true} />}
    </>
  );
}
