import React, { useEffect, useState } from 'react';
import './Module CSS/Module.scss';
import Interested from './Interested';
import { CourseData } from '../../Constants/CourseData';
import machinelearning from './ModulesImages/machinelearning.jpg';
import Curriculum from './ModulesInnerComponents/Curriculum';
import Instructor from './ModulesInnerComponents/Instructor';
import FAQs from './ModulesInnerComponents/FAQs';
import Outcome from './ModulesInnerComponents/Outcome';
import Benefits from './ModulesInnerComponents/Benefits';
import Overview from './ModulesInnerComponents/Overview';
import WhoShouldAttend from './ModulesInnerComponents/WhoShouldAttend';
import { Helmet } from 'react-helmet';


export default function MachineLearning() {

  const [scroll, setScroll] = useState(false);
  const heightFromTop = 25;

  useEffect(() => {
    const scrollFunc = () => {
      const scrolled = window.scrollY >= heightFromTop;
      setScroll(scrolled);
    }

    window.addEventListener('scroll', scrollFunc);

    return () => {
      window.removeEventListener('scroll', scrollFunc);
    }
  }, [heightFromTop])
	
  return (
    <>
    <Helmet>
      <title>
      Best Python Institute In Delhi | Best Python Coaching in Noida | PST Analytics
      </title>
      <meta name="keywords" content="Best Python Institute In Delhi, best python coaching in Noida, Python Training In Delhi, Python Course In Delhi, Python Training In Gurgaon" />
      <meta name="description" content="Master Data Science with R & Python. Also, Get Training in Python Machine Learning from PST Analytics, the  best Coaching institute for advance analytics proplus in Noida, Delhi and Gurgaon." />
    </Helmet>
      <div className="container-fluid course-card">
        <div className='row'>
          <div className={scroll ? 'img_containerScrolled' : 'img_containerNormal col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9'} style={{ background: `url(${machinelearning})` }}>
            <div className='bg-div'>
              <h1 className='courseHeading'>{CourseData.Machine_Learning_And_AI_Using_Python.Title}</h1>
              <div className='desc_'>
                <p>{CourseData.Machine_Learning_And_AI_Using_Python.Subtitle}</p>
                <h5>{CourseData.Machine_Learning_And_AI_Using_Python.Time} hours</h5>
                <h5>{CourseData.Machine_Learning_And_AI_Using_Python.Students}</h5>
                <h5>Basic to Advance</h5>
                <h5>Certification Course</h5>
              </div>
            </div>
          </div>
          <div className={scroll ? 'scrolledInterestedDiv col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3' : 'col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 normalInterestedDiv'}>
            <Interested />
          </div>
        </div>

        {/* Navbar */}


        <ul className={scroll ? 'nav justify-content-center  nav-tabs container description_Nav_Scrolled' : 'nav justify-content-center nav-tabs container description_Nav_Normal'} id="list-example" >
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#list-item-1">Overview</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-2">Benefits</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-3">Outcome</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-4">Curriculum</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-5">Instructor</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-6">FAQs</a>
          </li>
        </ul>



        {/* overview */}


        <div className="container overview_Cont" >
          <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-offset="0" className="scrollspy-example" tabIndex="0">
            <div id="list-item-1" className={scroll ? 'list-item-1scrolled' : 'list-item-1normal'} >
              <h4 style={scroll ? {} : { marginTop: '2rem' }} >What you'll learn</h4>
              <Overview What_YouLL_Learn={CourseData.Machine_Learning_And_AI_Using_Python.What_YouLL_Learn} Quick_Stats={CourseData.Machine_Learning_And_AI_Using_Python.Quick_Stats}/>
            </div>
            <div id="list-item-2">
              <h4>Benefits</h4>
              <Benefits Benefits={CourseData.Machine_Learning_And_AI_Using_Python.Benefits}/>
              <h4>Who Should Attend</h4>
              <WhoShouldAttend WhoShouldAttend={CourseData.Machine_Learning_And_AI_Using_Python.Who_Should_Attend}/>
            </div>
            <div id="list-item-3">
              <h4>Course Outcome</h4>
              <Outcome Outcome={CourseData.Machine_Learning_And_AI_Using_Python.Course_Outcome}/>
            </div>
            <div id="list-item-4">
              <h4>Curriculum</h4>
              <Curriculum Curriculum={CourseData.Machine_Learning_And_AI_Using_Python.Curriculum}/>
            </div>
            <div id="list-item-5">
              <h4>Instructor</h4>
              <Instructor Instructors={CourseData.Machine_Learning_And_AI_Using_Python.Instructors}/>
            </div>
            <div id="list-item-6">
              <h4>FAQs</h4>
              <FAQs FAQs={CourseData.Machine_Learning_And_AI_Using_Python.FAQs}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
