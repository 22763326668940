import React from "react";
import "./SCSS/FAQs.scss";

export default function FAQs({ FAQs }) {
  return (
    <>
      <div className="FAQs">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {FAQs.length > 0 &&
            FAQs.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${index}ques`}
                    aria-expanded="false"
                    aria-controls={index+"ques"}
                  >
                    {item.Question}
                  </button>
                </h2>
                <div
                  id={index+"ques"}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushFAQs"
                >
                  <div className="accordion-body">
                    <p>
                      {item.Answer}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
