import React, { useEffect, useState } from "react";
import "./Module CSS/Module.scss";
import Interested from "./Interested";
import { CourseData } from "../../Constants/CourseData";
import python from "./ModulesImages/python.jpg";
import Curriculum from "./ModulesInnerComponents/Curriculum";
import Instructor from "./ModulesInnerComponents/Instructor";
import FAQs from "./ModulesInnerComponents/FAQs";
import Outcome from "./ModulesInnerComponents/Outcome";
import Benefits from "./ModulesInnerComponents/Benefits";
import Overview from "./ModulesInnerComponents/Overview";
import WhoShouldAttend from "./ModulesInnerComponents/WhoShouldAttend";
import { Helmet } from "react-helmet";

export default function Python() {
  const [scroll, setScroll] = useState(false);
  const heightFromTop = 25;

  useEffect(() => {
    const scrollFunc = () => {
      const scrolled = window.scrollY >= heightFromTop;
      setScroll(scrolled);
    };

    window.addEventListener("scroll", scrollFunc);

    return () => {
      window.removeEventListener("scroll", scrollFunc);
    };
  }, [heightFromTop]);

  return (
    <>
      <Helmet>
        <title>
          Python Training In Delhi | Python Course In Delhi | Python Training In
          Gurgaon | Python Training
        </title>
        <meta
          name="description"
          content="PST Analytics provides the best python coaching in Noida, Python Training In Delhi, Python Course In Delhi, Python Training In Gurgaon, and Delhi NCR. Learn concepts of Analytics Predictive modelling with the hands-on application through SAS SQL R Python Excel for cracking global certification with PST Analytics, classroom training."
        />
        t
        <meta
          name="keywords"
          content="Python Training In Delhi, Python Course In Delhi, Python Training In Gurgaon, Best Python Institute In Delhi, best python coaching in noida Analytics Credit Risk Marketing Risk SASGlobal Certification Macros"
        />
      </Helmet>

      <div className="container-fluid course-card">
        <div className="row">
          <div
            className={
              scroll
                ? "img_containerScrolled"
                : "img_containerNormal col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9"
            }
            style={{ background: `url(${python})` }}
          >
            <div className="bg-div">
              <h1 className="courseHeading">{CourseData.Python.Title}</h1>
              <div className="desc_">
                <p>{CourseData.Python.Subtitle}</p>
                <h5>{CourseData.Python.Time} hours</h5>
                <h5>{CourseData.Python.Students}</h5>
                <h5>Basic to Advance</h5>
                <h5>Certification Course</h5>
              </div>
            </div>
          </div>
          <div
            className={
              scroll
                ? "scrolledInterestedDiv col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"
                : "col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 normalInterestedDiv"
            }
          >
            <Interested />
          </div>
        </div>

        {/* Navbar */}

        <ul
          className={
            scroll
              ? "nav justify-content-center  nav-tabs container description_Nav_Scrolled"
              : "nav justify-content-center nav-tabs container description_Nav_Normal"
          }
          id="list-example"
        >
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#list-item-1">
              Overview
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-2">
              Benefits
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-3">
              Outcome
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-4">
              Curriculum
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-5">
              Instructor
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-6">
              FAQs
            </a>
          </li>
        </ul>

        {/* overview */}

        <div className="container overview_Cont">
          <div
            data-bs-spy="scroll"
            data-bs-target="#list-example"
            data-bs-offset="0"
            className="scrollspy-example"
            tabIndex="0"
          >
            <div
              id="list-item-1"
              className={scroll ? "list-item-1scrolled" : "list-item-1normal"}
            >
              <h4 style={scroll ? {} : { marginTop: "2rem" }}>
                What you'll learn
              </h4>
              <Overview
                What_YouLL_Learn={CourseData.Python.What_YouLL_Learn}
                Quick_Stats={CourseData.Python.Quick_Stats}
              />
            </div>
            <div id="list-item-2">
              <h4>Benefits</h4>
              <Benefits Benefits={CourseData.Python.Benefits} />
              <h4>Who Should Attend</h4>
              <WhoShouldAttend
                WhoShouldAttend={CourseData.Python.Who_Should_Attend}
              />
            </div>
            <div id="list-item-3">
              <h4>Course Outcome</h4>
              <Outcome Outcome={CourseData.Python.Course_Outcome} />
            </div>
            <div id="list-item-4">
              <h4>Curriculum</h4>
              <Curriculum Curriculum={CourseData.Python.Curriculum} />
            </div>
            <div id="list-item-5">
              <h4>Instructor</h4>
              <Instructor Instructors={CourseData.Python.Instructors} />
            </div>
            <div id="list-item-6">
              <h4>FAQs</h4>
              <FAQs FAQs={CourseData.Python.FAQs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// default: title = 'Data Science Training and Machine Learning Courses in Delhi NCR and Gurgaon';
// 			keywords = 'data science course in delhi, data science course in delhi ncr, data science course in gurgaon, data science training in delhi, data science training in gurgaon, data science training in new delhi, data analytics courses in delhi, data analytics training in delhi, machine learning course in delhi, machine learning training in delhi';
// 			description = 'PST Analytics offers Data Science training and Machine Learning Course in Delhi, Gurgaon and NCR. We provide classroom data analytics training with global certification and certification courses.';
