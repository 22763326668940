import React from 'react';
import './SCSS/Curriculum.scss';

export default function Curriculum({Curriculum}) {
  return (
    <>
      <div className='Curriculum'>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {
            Curriculum.length>0 && Curriculum.map((item,index)=>(
              <div className="accordion-item" key={index}>
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${index}`} aria-expanded="false" aria-controls={index}>
                  {item.Step}
                </button>
              </h2>
              <div id={index} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  {
                    item.innerDetail.length>0 && item.innerDetail.map((item1,index1)=>(
                      <p key={index1}>{item1}</p>
                    ))
                  }
                </div>
              </div>
            </div>
            ))
          }
        </div>
      </div>
    </>
  )
}
