import { Route, Routes, useLocation } from "react-router-dom";
import Nav from "./Components/Nav";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Industry from "./Components/Modules/Industry360";
import MachineLearning from "./Components/Modules/MachineLearning";
import Python from "./Components/Modules/Python";
import DataAnalytics from "./Components/Modules/DataAnalytics";
import Corporate from "./Components/For business/Corporate";
import University from "./Components/For business/University";
import HireFromUs from "./Components/HireFromUs";
import ContactUsPage from "./Components/ContactUsPage";
import Blogs from "./Components/Blogs";
import DataVisualizationTableu from "./Components/Modules/DataVisualizationTableu";
import DataVisualizationPowerBI from "./Components/Modules/DataVisualizationPowerBi";
import Testimonials from "./Components/Testimonials";
import Certificates from "./Components/Certificates";
import { useEffect } from "react";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Nav />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Industry />} path="/industry-360" />
        <Route element={<DataAnalytics />} path="/data-analytics" />
        <Route element={<Python />} path="/analytics-pro" />
        <Route element={<Python />} path="/analytics-proplus" />
        <Route element={<Python />} path="/python" />
        <Route element={<MachineLearning />} path="/machine-learning" />
        <Route
          element={<DataVisualizationTableu />}
          path="/data-visualization"
        />
        <Route element={<DataVisualizationPowerBI />} path="/power-bi" />
        <Route path="/corporate-training" element={<Corporate />} />
        <Route path="/university-training" element={<University />} />
        <Route path="/hire-from-us" element={<HireFromUs />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/certificates" element={<Certificates />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
