import React from 'react';
import '../CSS/IIofAnalyticsCard.scss';
import { Link } from 'react-router-dom';

export default function IIofAnalyticsCard({p, img}) {
  return (
    <>
      <div className="col col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 IIofAnalyticsCard" >
        <img src={img} className="card-img-top" alt="..." />
        <div className="card-body">
          <h6 className='h6'>{p}</h6>
          <section className='explore_link_div'>
            <Link to={''} className='explore_link'>Explore--</Link>
          </section>
        </div>
      </div>
    </>
  )
}
