import React from 'react';
import '../CSS/Footer.scss';
import logo from '../Media/logo.png'
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <>
            <div className="container-fluid footer">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <img src={logo} alt="--" style={{ width: '40%', height: 'auto' }} />
                            <p>PST Analytics is one of the best and most recommended institutes providing training to the people all around the globe.</p>
                        </div>
                        {/* ----------------- */}
                        <div className="col col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 Students">
                            <h5>Students</h5>
                            <Link to={'/hire-from-us'}><p>Hire From Us</p></Link>
                            <Link to={'/university-training'}><p>University Training</p></Link>
                            <Link to={'/certificates'}><p>Certificates</p></Link>
                        </div>
                        {/* ----------------- */}
                        <div className="col col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 Explore">
                            <h5>Explore</h5>
                            <Link to={'/blogs'}><p>Blog</p></Link>
                            <Link to={'/corporate-training'}><p>Corporate Training</p></Link>
                            <Link to={'/testimonials'}><p>Student Testimonials</p></Link>
                        </div>
                        {/* ----------------- */}
                        <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 Get_Contact">
                            <h5>Contact Us</h5>
                            <a href=""><p>Phone: +918377953224</p></a>
                            <a href=""><p>E-mail: info@pstanalytics.com</p></a>
                            <a href=""><p>Location: Operating Online</p></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
