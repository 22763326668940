import React, { useEffect, useState } from 'react';
import './Module CSS/Module.scss';
import { CourseData } from '../../Constants/CourseData';
import Interested from './Interested';
import powerbi from './ModulesImages/powerbi.jpg';
import Curriculum from './ModulesInnerComponents/Curriculum';
import Instructor from './ModulesInnerComponents/Instructor';
import FAQs from './ModulesInnerComponents/FAQs';
import Outcome from './ModulesInnerComponents/Outcome';
import Benefits from './ModulesInnerComponents/Benefits';
import Overview from './ModulesInnerComponents/Overview';
import WhoShouldAttend from './ModulesInnerComponents/WhoShouldAttend';
import { Helmet } from 'react-helmet';


export default function DataVisualizationPowerBI() {

  const [scroll, setScroll] = useState(false);
  const heightFromTop = 25;

  useEffect(() => {
    const scrollFunc = () => {
      const scrolled = window.scrollY >= heightFromTop;
      setScroll(scrolled);
    }

    window.addEventListener('scroll', scrollFunc);

    return () => {
      window.removeEventListener('scroll', scrollFunc);
    }
  }, [heightFromTop])


  return (
    <>
    <Helmet>
        <title>
          Best Power Bi Training Institute in Gurgaon, Delhi & Noida | Power Bi
          Global Certification | Power Bi Course Udemy
        </title>
        <meta
          name="description"
          content="Learn Data Visualization concepts with Power Bi hands-on at PST Analytics Classroom and Online Power Bi Training and Certification Courses in Delhi, Gurgaon, Noida and other Indian cities. With our Power Bi online training, Power Bi course udemy be ready for Power Bi global certification."
        />
        <meta
          name="keywords"
          content="Power Bi Training, online Power Bi course, Power Bi Training In Gurgaon, Best Power Bi Training In Delhi NCR, Power Bi training institute in noida, Power Bi classes in Noida, Power Bi Global Certification, Best Power Bi Training Institute in Gurgaon, Delhi & Noida"
        />
      </Helmet>
      <div className="container-fluid course-card">
        <div className='row'>
          <div className={scroll ? 'img_containerScrolled': 'img_containerNormal col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9'}    style={{ background:`url(${powerbi})`}}>
            <div className='bg-div'>
              <h1 className='courseHeading'>{CourseData.Data_Visualization_PowerBi.Title.slice(0,-2)} <span>BI</span></h1>
              <div className='desc_'>
                <p>{CourseData.Data_Visualization_PowerBi.Subtitle}</p>
                <h5>{CourseData.Data_Visualization_PowerBi.Time} hours</h5>
                <h5>{CourseData.Data_Visualization_PowerBi.Students}</h5>
                <h5>Basic to Advance</h5>
                <h5>Certification Course</h5>
              </div>
            </div>
          </div>
          <div className={scroll ? 'scrolledInterestedDiv col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3' : 'col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 normalInterestedDiv'}>
             <Interested />
          </div>
        </div>


        {/* Navbar */}


        <ul className={scroll ? 'nav justify-content-center  nav-tabs container description_Nav_Scrolled' : 'nav justify-content-center nav-tabs container description_Nav_Normal'} id="list-example" >
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="#list-item-1">Overview</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-2">Benefits</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-3">Outcome</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-4">Curriculum</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-5">Instructor</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#list-item-6">FAQs</a>
          </li>
        </ul>



        {/* overview */}


        <div className="container overview_Cont" >
          <div data-bs-spy="scroll" data-bs-target="#list-example" data-bs-offset="0" className="scrollspy-example" tabIndex="0">
            <div id="list-item-1" className={scroll ? 'list-item-1scrolled' : 'list-item-1normal'} >
              <h4 style={scroll ? {} : { marginTop: '2rem' }} >What you'll learn</h4>
              <Overview What_YouLL_Learn={CourseData.Data_Visualization_PowerBi.What_YouLL_Learn}  Quick_Stats={CourseData.Data_Visualization_PowerBi.Quick_Stats} />
            </div>
            <div id="list-item-2">
              <h4>Benefits</h4>
              <Benefits Benefits={CourseData.Data_Visualization_PowerBi.Benefits}/>
              <h4>Who Should Attend</h4>
              <WhoShouldAttend WhoShouldAttend={CourseData.Data_Visualization_PowerBi.Who_Should_Attend}/>
            </div>
            <div id="list-item-3">
              <h4>Course Outcome</h4>
              <Outcome Outcome={CourseData.Data_Visualization_PowerBi.Course_Outcome}/>
            </div>
            <div id="list-item-4">
              <h4>Curriculum</h4>
              <Curriculum Curriculum={CourseData.Data_Visualization_PowerBi.Curriculum}/>
            </div>
            <div id="list-item-5">
              <h4>Instructor</h4>
              <Instructor Instructors={CourseData.Data_Visualization_PowerBi.Instructors}/>
            </div>
            <div id="list-item-6">
              <h4>FAQs</h4>
              <FAQs FAQs={CourseData.Data_Visualization_PowerBi.FAQs}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
