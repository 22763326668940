import React from "react";
import logo from "../Media/logo.png";
// import batchicon from "../Media/batchIcon.png";
import "../CSS/Nav.scss";
import { NavLink } from "react-router-dom";

export default function Nav() {
  return (
    <>
      <div className="container-fluid fixed-top bg-none mnvbar">
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4">
          <a href="/" className="d-flex align-items-center col-1 mb-2 mb-md-0">
            <img src={logo} alt="logo" />
          </a>
          {/* <a href="/" className="d-flex align-items-left col-1 mb-2 mb-md-0">
            <img
              src={batchicon}
              alt="BaTCH STARTS FROM"
              className="batchicon"
            />
          </a> */}

          <ul className="nav col-8 mb-2 justify-content-center mb-md-0 gap-3">
            <li>
              <NavLink to={"/"} className="nav-link px-2">
                Home
              </NavLink>
            </li>
            <li className="dropdown">
              <NavLink
                className="nav-link px-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Module
              </NavLink>
              <ul className="dropdown-menu">
                <li>
                  <NavLink
                    className="dropdown-item"
                    to={"/industry-360"}
                  >
                    Industry 360
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to={"/data-analytics"}
                  >
                    Data Analytics using SAS
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to={"/python"}>
                    Python
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to={"/machine-learning"}
                  >
                    Machine Learning and AI using Python
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to={"/data-visualization"}
                  >
                    Data Visualization - Tableau
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to={"/power-bi"}>
                    Data Visualization - Power BI
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <NavLink
                className="nav-link px-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                For business
              </NavLink>
              <ul className="dropdown-menu">
                <li>
                  <NavLink className="dropdown-item" to={"/corporate-training"}>
                    Corporate Training
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to={"/university-training"}>
                    University Training
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to={"/hire-from-us"} className="nav-link px-2">
                Hire from us
              </NavLink>
            </li>
            <li>
              <NavLink to={"/blogs"} className="nav-link px-2">
                Blogs
              </NavLink>
            </li>
            <li>
              <NavLink to={"/contact-us"} className="nav-link px-2">
                Contact
              </NavLink>
            </li>
          </ul>
        </header>
      </div>
      {/* -------------------------------------------------------------------------------- */}
      <div className="container-fluid fixed-top bg-none mnvbar_MobileView">
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4">
          <a href="/" className="d-flex align-items-center col-1 mb-2 mb-md-0">
            <img src={logo} alt="logo" />
          </a>
          {/* <a href="/" className="d-flex align-items-left col-2 mb-2 mb-md-0">
            <img
              src={batchicon}
              alt="BaTCH STARTS FROM"
              className="batchicon"
            />
          </a> */}
          <div className="col-5 text-end">
            <button
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              <i className="bi bi-columns-gap"></i>
            </button>
          </div>
        </header>
      </div>

      {/* canvas */}
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <a href="/" className="d-flex align-items-center col-1 mb-2 mb-md-0">
            <img src={logo} alt="logo" />
          </a>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav col-8 mb-2 justify-content-center mb-md-0 gap-3">
            <li aria-label="Close" data-bs-dismiss="offcanvas">
              <NavLink to={"/"} className="nav-link px-2">
                Home
              </NavLink>
            </li>
            <li className="dropdown">
              <NavLink
                className="nav-link px-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Module
              </NavLink>
              <ul className="dropdown-menu">
                <li aria-label="Close" data-bs-dismiss="offcanvas">
                  <NavLink
                    className="dropdown-item"
                    to={"/industry-360"}
                  >
                    Industry 360
                  </NavLink>
                </li>
                <li aria-label="Close" data-bs-dismiss="offcanvas">
                  <NavLink
                    className="dropdown-item"
                    to={"/data-analytics"}
                  >
                    Data Analytics using SAS
                  </NavLink>
                </li>
                <li aria-label="Close" data-bs-dismiss="offcanvas">
                  <NavLink className="dropdown-item" to={"/python"}>
                    Python
                  </NavLink>
                </li>
                <li aria-label="Close" data-bs-dismiss="offcanvas">
                  <NavLink
                    className="dropdown-item"
                    to={"/machine-learning"}
                  >
                    Machine Learning and AI using Python
                  </NavLink>
                </li>
                <li aria-label="Close" data-bs-dismiss="offcanvas">
                  <NavLink
                    className="dropdown-item"
                    to={"/data-visualization"}
                  >
                    Data Visualization - Tableau
                  </NavLink>
                </li>
                <li aria-label="Close" data-bs-dismiss="offcanvas">
                  <NavLink className="dropdown-item" to={"/power-bi"}>
                    Data Visualization - Power BI
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <NavLink
                className="nav-link px-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                For business
              </NavLink>
              <ul className="dropdown-menu">
                <li aria-label="Close" data-bs-dismiss="offcanvas">
                  <NavLink className="dropdown-item" to={"/corporate-training"}>
                    Corporate Training
                  </NavLink>
                </li>
                <li aria-label="Close" data-bs-dismiss="offcanvas">
                  <NavLink className="dropdown-item" to={"/university-training"}>
                    University Training
                  </NavLink>
                </li>
              </ul>
            </li>
            <li aria-label="Close" data-bs-dismiss="offcanvas">
              <NavLink to={"/hire-from-us"} className="nav-link px-2">
                Hire from us
              </NavLink>
            </li>
            <li aria-label="Close" data-bs-dismiss="offcanvas">
              <NavLink to={"/blogs"} className="nav-link px-2">
                Blogs
              </NavLink>
            </li>
            <li aria-label="Close" data-bs-dismiss="offcanvas">
              <NavLink to={"/contact-us"} className="nav-link px-2">
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      {/* canvas */}
    </>
  );
}
