import React from "react";
import "../CSS/Loading.scss";

export default function Loading({ contactPage }) {
  return (
    <div
      className="container-fluid Loading"
      style={{
        zIndex: 3,
        position: contactPage ? "fixed" : "relative",
        top: "0",
        left: "0",
        width: "100%",
        height: contactPage ? "150rem" : "100%",
        backdropFilter: "blur(1px)",
      }}
    >
      <div className="row d-flex justify-content-center">
        <div className="col-12 text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
}
