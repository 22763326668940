import React, { useEffect, useState } from 'react';
import '../CSS/Blogs.scss';
import blogsImg from '../Media/blogs.jpg'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Blogs() {
    const [scroll, setScroll] = useState(false);
    const heightFromTop = 25;

    useEffect(() => {
        const scrollFunc = () => {
            const scrolled = window.scrollY >= heightFromTop;
            setScroll(scrolled);
        }

        window.addEventListener('scroll', scrollFunc);

        return () => {
            window.removeEventListener('scroll', scrollFunc);
        }
    }, [heightFromTop])

    return (
        <>
            <Helmet>
        <title>Blogs | PST Analytics</title>
      </Helmet>
            <div className="container-fluid Blogs_Container">
                <div className={scroll ? 'row rowScrolledDiv' : 'row rowNormalDiv'} style={scroll ? { background: 'none' } : { background: `url(${blogsImg})` }}>
                    <div className='col-12' >
                        <h1>
                            Blogs
                        </h1>
                    </div>
                </div>


                {/* searchbar */}
                {/* <div className={scroll ? 'search_divScrolled' : 'search_divNormal'}>
                    <input type="search" placeholder='Search'/>
                </div> */}


                <div className={scroll ? 'rowScrolledDatadiv' : 'rowNormalDatadiv'}>
                    <div className="container">

                        <div className="descrip">
                            <div>
                                <div className='icon_'><i className="bi bi-blockquote-left"></i></div>
                                <div className='text_'>
                                    <h6>SAS training in Delhi NCR Gurgaon testimony Professional SAS Tutors</h6>
                                    <p>Professional SAS Tutors course in Business Analytics was recommended to me by many folks. I had always been passionate about data and to be true overconfident as well with</p>
                                    <Link to={''}>{'Read article->'}</Link>
                                </div>
                            </div>

                            <div>
                                <div className='icon_'><i className="bi bi-blockquote-left"></i></div>
                                <div className='text_'>
                                    <h6>Analytics Training in Delhi</h6>
                                    <p>Business analytics is the art of using the logical reasoning in day to day business. Decisions are made on the outcomes of these logics. To support the logic we</p>
                                    <Link to={''}>{'Read article->'}</Link>
                                </div>
                            </div>

                            <div>
                                <div className='icon_'><i className="bi bi-blockquote-left"></i></div>
                                <div className='text_'>
                                    <h6>Business Analytics Training in Delhi</h6>
                                    <p>According to the Harvard Business Review, the Data Scientist is the 'Sexiest Job of the 21st Century'. NASSCOM has estimated that from 50,000 today, the demand for Analytics professionals in</p>
                                    <Link to={''}>{'Read article->'}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
